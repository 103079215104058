// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useInlineFormMutation,
  useNavigation,
  useQuery,
  useResponsive,
  ResponsiveType,
} from '@supermove/hooks';
import {SettingsModel, UserModel, OrganizationModel} from '@supermove/models';

// App
import Callout from '@shared/design/components/Callout';
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import SettingsSection from '@shared/modules/Settings/components/SettingsSection';
import SettingsSectionOption from '@shared/modules/Settings/components/SettingsSectionOption';
import CalendarKind from '@shared/modules/Settings/enums/CalendarKind';
import SettingsForm, {SettingsFormType} from '@shared/modules/Settings/forms/SettingsForm';
import CompanyDetailsPage from 'modules/Company/CompanyDetails/CompanyDetailsPage';

const CalloutContainer = Styled.View`
  width: fit-content;
`;

const CalendarsSettingsContainer = Styled.View`
  padding-horizontal: ${({responsive}: {responsive: ResponsiveType}) => (responsive.desktop ? 0 : 16)}px;
`;

const useToggleSettingsIsMovesCalendarEnabledMutation = ({
  settingsForm,
  refetch,
}: {
  settingsForm: SettingsFormType;
  refetch: () => void;
}) => {
  return useInlineFormMutation({
    name: 'settingsForm',
    form: settingsForm,
    toForm: SettingsForm.toForm,
    toMutation: SettingsForm.toMutation,
    mutation: gql`
      mutation useToggleSettingsIsMovesCalendarEnabledMutation($settingsForm: SettingsForm!) {
        response: toggleSettingsIsMovesCalendarEnabled(settingsForm: $settingsForm) {
          ${gql.errors}
          settings {
            id
            isMovesCalendarEnabled
          }
        }
      }
    `,
    onSuccess: refetch,
  });
};

const useToggleSettingsIsBookingCalendarEnabledMutation = ({
  settingsForm,
  refetch,
}: {
  settingsForm: SettingsFormType;
  refetch: () => void;
}) => {
  return useInlineFormMutation({
    name: 'settingsForm',
    form: settingsForm,
    toForm: SettingsForm.toForm,
    toMutation: SettingsForm.toMutation,
    mutation: gql`
      mutation useToggleSettingsIsBookingCalendarEnabledMutation($settingsForm: SettingsForm!) {
        response: toggleSettingsIsBookingCalendarEnabled(settingsForm: $settingsForm) {
          ${gql.errors}
          settings {
            id
            isBookingCalendarEnabled
          }
        }
      }
    `,
    onSuccess: refetch,
  });
};

const useUpdateSettingsPrimaryCalendarMutation = ({
  settings,
  viewer,
  refetch,
}: {
  settings: SettingsModel;
  viewer: UserModel;
  refetch: () => void;
}) => {
  return useInlineFormMutation({
    name: 'settingsForm',
    form: {
      settingsId: settings.id,
      primaryCalendar: settings.primaryCalendar,
      updatedById: viewer.id,
    },
    mutation: gql`
      mutation useUpdateSettingsPrimaryCalendarMutation($settingsForm: SettingsForm!) {
        response: updateSettingsPrimaryCalendar(settingsForm: $settingsForm) {
          ${gql.errors}
          settings {
            id
            primaryCalendar
          }
        }
      }
    `,
    onSuccess: refetch,
  });
};

const CalendarsSettings = ({
  settings,
  viewer,
  organization,
  refetch,
}: {
  settings: SettingsModel;
  viewer: UserModel;
  organization: OrganizationModel;
  refetch: () => void;
}) => {
  const isDisabled = !organization.features.isEnabledLegacyCalendarConfig;
  const {isBookingCalendarEnabled, isMovesCalendarEnabled} = settings;
  const settingsForm = SettingsForm.edit(settings, {updatedById: viewer.id});
  const {handleSubmit: isMovesCalendarEnabledHandleSubmit} =
    useToggleSettingsIsMovesCalendarEnabledMutation({settingsForm, refetch});
  const {handleSubmit: isBookingCalendarEnabledHandleSubmit} =
    useToggleSettingsIsBookingCalendarEnabledMutation({settingsForm, refetch});
  const {handleSubmit: primaryCalendarHandleSubmit, form: primaryCalendarForm} =
    useUpdateSettingsPrimaryCalendarMutation({
      settings,
      viewer,
      refetch,
    });

  return (
    <SettingsSection title={'Move Calendars (Legacy)'}>
      <SettingsSectionOption
        name={'Show Moves Calendar'}
        description={
          `Enables the Moves calendar in the “Moves” tab. ` +
          `This calendar shows an overview of how many jobs there are in a month, as well as a view of all the jobs on a given day.`
        }
        isDisabled={isDisabled}
        isEnabled={isMovesCalendarEnabled}
        onPress={isMovesCalendarEnabledHandleSubmit}
      />
      <Space height={16} />
      <SettingsSectionOption
        name={'Show Booking Calendar'}
        description={`Enables the Booking calendar in the “Moves” tab. This calendar shows an overview of the jobs within a week.`}
        isDisabled={isDisabled}
        isEnabled={isBookingCalendarEnabled}
        onPress={isBookingCalendarEnabledHandleSubmit}
      />
      <Space height={16} />
      <SettingsSectionOption
        name={'Primary Calendar'}
        description={`Select the primary calendar to be shown in the “Moves” tab. If the Moves calendar is switched off, then this will default to the Capacity calendar.`}
        isDisabled={isDisabled || !isMovesCalendarEnabled}
        ActionButtonComponent={FieldInput}
        actionButtonComponentProps={{
          ...primaryCalendarForm,
          name: 'settingsForm.primaryCalendar',
          component: DropdownInput,
          isResponsive: true,
          input: {
            isDisabled: isDisabled || !isMovesCalendarEnabled,
            isPortaled: true,
            options: CalendarKind.getDropdownOptions(),
            style: {width: '100%'},
            setFieldValue: primaryCalendarForm.setFieldValue,
            onChangeValue: (value: any) => {
              primaryCalendarForm.setFieldValue('settingsForm.primaryCalendar', value);
              setImmediate(primaryCalendarHandleSubmit);
            },
          },
          style: {flex: 1},
        }}
      />
    </SettingsSection>
  );
};

const CompanyModulesCalendarsPage = () => {
  const {params} = useNavigation();
  const responsive = useResponsive();
  const {loading, data, refetch} = useQuery(CompanyModulesCalendarsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {slug: params.slug},
  });

  return (
    <CompanyDetailsPage
      selectedLabel={'modules/calendars'}
      pageTitle={'Calendars'}
      pageDescription={
        'Configure calendar settings. These features will apply to the company and, if applicable, its branches'
      }
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          const {primaryOrganization: organization} = data.company;

          return (
            <CalendarsSettingsContainer responsive={responsive}>
              <CalloutContainer>
                <Callout
                  text={
                    'All companies should be using the Capacity Calendar. These settings are only for legacy customers.'
                  }
                />
              </CalloutContainer>
              <Space height={24} />
              <CalendarsSettings
                settings={organization.settings}
                viewer={data.viewer}
                organization={organization}
                refetch={refetch}
              />
            </CalendarsSettingsContainer>
          );
        }}
      </Loading>
    </CompanyDetailsPage>
  );
};

CompanyModulesCalendarsPage.query = gql`
  ${SettingsForm.edit.fragment}
  query CompanyModulesCalendarsPage($slug: String!) {
    ${gql.query}
    viewer {
      id
    }
    company(slug: $slug) {
      id
      primaryOrganization {
        id
        settings {
          id
          isBookingCalendarEnabled
          isMovesCalendarEnabled
          ...SettingsForm_edit
        }
        features {
          isEnabledLegacyCalendarConfig: isEnabled(feature: "LEGACY_CALENDAR_CONFIG")
        }
      }
    }
  }
`;

export default CompanyModulesCalendarsPage;
