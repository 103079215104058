// Supermove
import {gql} from '@supermove/graphql';
import {CompanyModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

export interface CompanyDetailsExternalLinksFormType {
  companyId: string;
  salesforceId: string;
  slackChannelId: string;
}

const edit = withFragment(
  (company: CompanyModel) => ({
    companyId: company.id,
    salesforceId: company.salesforceId,
    slackChannelId: company.slackChannelId,
  }),
  gql`
    fragment CompanyDetailsExternalLinksForm_edit on Company {
      id
      salesforceId
      slackChannelId
    }
  `,
);

const toForm = ({
  companyId,
  salesforceId,
  slackChannelId,
}: CompanyDetailsExternalLinksFormType) => ({
  companyId,
  salesforceId,
  slackChannelId,
});

const toMutation = ({
  companyId,
  salesforceId,
  slackChannelId,
}: CompanyDetailsExternalLinksFormType) => ({
  companyId,
  salesforceId,
  slackChannelId,
});

const CompanyDetailsExternalLinksForm = {
  edit,
  toForm,
  toMutation,
};

export default CompanyDetailsExternalLinksForm;
