// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Checkbox from '@shared/design/components/Checkbox';
import IconButton from '@shared/design/components/IconButton';

export interface OrganizationData {
  id: string;
  name: string;
  primaryCompanyOrganizationId: string;
}

const Container = Styled.View`
  background-color: ${colors.gray.background};
  padding: 8px 24px;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`;

const NameText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.primary};
`;

interface ManageGlobalDashboardCompaniesDrawerListGroupHeaderItemProps {
  title: string;
  isSelected: boolean;
  isOpen: boolean;
  handleSelect: (isSelected: boolean) => void;
  handleOpen: () => void;
}

const ManageGlobalDashboardCompaniesDrawerListGroupHeaderItem = ({
  title,
  isSelected,
  isOpen,
  handleSelect,
  handleOpen,
}: ManageGlobalDashboardCompaniesDrawerListGroupHeaderItemProps) => {
  const responsive = useResponsive();
  return (
    <Container>
      <Checkbox isChecked={isSelected} handleToggle={() => handleSelect(!isSelected)} />
      <Space width={32} />
      <IconButton
        source={isOpen ? Icon.AngleDown : Icon.AngleUp}
        onPress={handleOpen}
        isSecondary
      />
      <Space width={8} />
      <NameText responsive={responsive}>{title}</NameText>
    </Container>
  );
};

export default ManageGlobalDashboardCompaniesDrawerListGroupHeaderItem;
