// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import UpdateUserStatusForm from '@shared/modules/User/forms/UpdateUserStatusForm';

const useUpdateUserStatusMutation = ({updateUserStatusForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      updateUserStatusForm: UpdateUserStatusForm.toForm(updateUserStatusForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateUserStatusMutation.mutation,
    variables: {
      updateUserStatusForm: UpdateUserStatusForm.toMutation(form.values.updateUserStatusForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateUserStatusMutation.mutation = gql`
  mutation useUpdateUserStatusMutation($updateUserStatusForm: UpdateUserStatusForm!) {
    response: updateUserStatus(updateUserStatusForm: $updateUserStatusForm) {
      user {
        id
        status
      }
      ${gql.errors}
    }
  }
`;

export default useUpdateUserStatusMutation;
