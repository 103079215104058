// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

export interface UpdateUserStatusFormProps {
  userId: number;
  status: string;
}

const edit = withFragment(
  (user: any) => ({
    userId: user.id,
    status: user.status,
  }),
  gql`
    fragment UpdateUserStatusForm_edit on User {
      id
      status
    }
  `,
);

const toForm = ({userId, status}: {userId: number; status: string}) => ({
  userId,
  status,
});

const toMutation = ({userId, status}: {userId: number; status: string}) => ({
  userId,
  status,
});

const UpdateUserStatusForm = {
  edit,
  toForm,
  toMutation,
};

export default UpdateUserStatusForm;
