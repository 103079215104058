// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Checkbox from '@shared/design/components/Checkbox';

export interface OrganizationData {
  id: string;
  name: string;
  primaryCompanyOrganizationId: string;
}

const Container = Styled.View`
  background-color: ${colors.white};
  padding: 8px 24px;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

const CheckboxWithNameContainer = Styled.View`
  flex-direction: row;
  justify-content: flex-start;
`;

const NameText = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.gray.primary};
`;

interface ManageGlobalDashboardCompaniesDrawerListHeaderProps {
  isSelected: boolean;
  handleSelect: (isSelected: boolean) => void;
}

const ManageGlobalDashboardCompaniesDrawerListHeader = ({
  isSelected,
  handleSelect,
}: ManageGlobalDashboardCompaniesDrawerListHeaderProps) => {
  const responsive = useResponsive();
  return (
    <Container>
      <CheckboxWithNameContainer>
        <Checkbox isChecked={isSelected} handleToggle={() => handleSelect(!isSelected)} />
        <Space width={32} />
        <NameText responsive={responsive}>Company Name</NameText>
      </CheckboxWithNameContainer>
      <NameText responsive={responsive}>Has Report</NameText>
    </Container>
  );
};

export default ManageGlobalDashboardCompaniesDrawerListHeader;
