// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {generated, gqlTyped as gql, useTypedQuery as useQuery} from '@supermove/graphql';

// App
import DrawerWithClose from '@shared/design/components/Drawer/DrawerWithClose';
import ManageDashboardTagsList from 'modules/Dashboards/components/ManageDashboardTagsList';

const ManageDashboardTagsDrawer = ({
  isOpen,
  handleClose,
  refetch: refetchGlobalDashboards,
}: {
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
}) => {
  const {loading, data, refetch} = useQuery(ManageDashboardTagsDrawerQuery, {
    skip: !isOpen,
    fetchPolicy: 'cache-and-network',
  });

  const dashboardTags = (data?.dashboardTags || []).reduce((acc, dashboardTag) => {
    if (dashboardTag) {
      acc.push(dashboardTag);
    }
    return acc;
  }, [] as generated.DashboardTagFormEditFragment[]);

  return (
    <DrawerWithClose isOpen={isOpen} handleClose={handleClose} headerText={'Manage Tags'}>
      <Loading loading={!data || loading}>
        {() => (
          <ManageDashboardTagsList
            dashboardTags={dashboardTags}
            refetch={() => {
              refetchGlobalDashboards();
              refetch();
            }}
          />
        )}
      </Loading>
    </DrawerWithClose>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
const ManageDashboardTagsDrawerQuery = gql(`
  query ManageDashboardTagsDrawer {
    dashboardTags {
        id
        ...DashboardTagFormEdit
    }
  }
`);

export default ManageDashboardTagsDrawer;
