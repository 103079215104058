// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CompanyDetailsSizeForm, {
  CompanyDetailsSizeFormType,
} from '@shared/modules/Company/forms/CompanyDetailsSizeForm';

const useUpdateCompanyDetailsSizeMutation = ({
  companyDetailsSizeForm,
  onSuccess,
  onError,
}: {
  companyDetailsSizeForm: CompanyDetailsSizeFormType;
  onSuccess: (response: unknown) => void;
  onError: (error: unknown) => void;
}) => {
  const form = useForm({
    initialValues: {
      companyDetailsSizeForm: CompanyDetailsSizeForm.toForm(companyDetailsSizeForm),
    },
    enableReinitialize: true,
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateCompanyDetailsSizeMutation.mutation,
    variables: {
      companyDetailsSizeForm: CompanyDetailsSizeForm.toMutation(form.values.companyDetailsSizeForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateCompanyDetailsSizeMutation.mutation = gql`
  mutation useUpdateCompanyDetailsSizeMutation($companyDetailsSizeForm: CompanyDetailsSizeForm!) {
    response: updateCompanyDetailsSize(companyDetailsSizeForm: $companyDetailsSizeForm) {
      ${gql.errors}
      company {
        id
      }
    }
  }
`;

export default useUpdateCompanyDetailsSizeMutation;
