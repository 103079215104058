// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

interface SearchBarProps {
  searchQuery: string;
  placeholder: string;
  desktopWidth?: number;
  iconColor?: string;
  isClearable?: boolean;
  setSearchQuery: (text: string) => void;
}

const SearchBar = ({
  searchQuery,
  placeholder,
  desktopWidth = 242,
  iconColor = colors.gray.secondary,
  isClearable = false,
  setSearchQuery,
}: SearchBarProps) => {
  const responsive = useResponsive();
  return (
    <Row style={responsive.desktop ? {} : {flex: 1}}>
      <Icon source={Icon.MagnifyingGlass} size={16} color={iconColor} />
      <Space width={8} />
      <FieldInput
        isClearable={isClearable}
        clearBtnStyle={isClearable ? {top: 0, right: 0} : undefined}
        value={searchQuery}
        setFieldValue={(name: string, text: string) => setSearchQuery(text)}
        input={{
          placeholder,
          style: {
            padding: 0,
            paddingBottom: responsive.desktop ? 2 : 0,
            borderTopWidth: 0,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderBottomWidth: responsive.desktop ? 1 : 0,
            borderRadius: 0,
            borderColor: colors.gray.border,
            width: responsive.desktop ? desktopWidth : 'auto',
            flex: 1,
            height: 20,
            minHeight: 20,
          },
        }}
        style={{flex: 1}}
      />
    </Row>
  );
};

export default SearchBar;
