// Supermove
import {generated} from '@supermove/graphql';

// App
import DashboardCategoryForm from '@shared/modules/DashboardCategory/forms/DashboardCategoryForm';

const edit = (reportDashboards: generated.DashboardCategoryFormEditFragment[]) => ({
  dashboardCategoryForms: reportDashboards.map((reportDashboard) => {
    return DashboardCategoryForm.edit(reportDashboard);
  }),
});

const toForm = ({dashboardCategoryForms}: ReturnType<typeof edit>) => ({
  dashboardCategoryForms: dashboardCategoryForms.map((reportDashboardForm) => {
    return DashboardCategoryForm.toForm(reportDashboardForm);
  }),
});

const toMutation = ({
  dashboardCategoryForms,
}: {
  dashboardCategoryForms: generated.DashboardCategoryForm[];
}) => ({
  dashboardCategoryForms: dashboardCategoryForms.map((reportDashboardForm) => {
    return DashboardCategoryForm.toMutation(reportDashboardForm);
  }),
});

const ReorderDashboardCategoriesForm = {
  edit,
  toForm,
  toMutation,
};

export default ReorderDashboardCategoriesForm;
