// Supermove
import {useNavigation, useMountEffect} from '@supermove/hooks';

const Root = () => {
  const {navigator} = useNavigation();
  useMountEffect(() => {
    navigator.navigate('CompaniesListPage');
  });
  return null;
};

export default Root;
