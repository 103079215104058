// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';

// App
import EmptyState from '@shared/design/components/EmptyState';

const Container = Styled.View`
`;

interface ManageDashboardTagsEmptyStateProps {
  handleCreate: () => void;
}

const ManageDashboardTagsEmptyState = ({handleCreate}: ManageDashboardTagsEmptyStateProps) => {
  return (
    <Container>
      <Space height={32} />
      <EmptyState
        title={'No tags.'}
        primaryActionIcon={Icon.Plus}
        primaryActionText={'Add Tag'}
        handlePrimaryAction={handleCreate}
      />
      <Space height={32} />
    </Container>
  );
};

export default ManageDashboardTagsEmptyState;
