// Supermove
import {gql} from '@supermove/graphql';
import {CompanyModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

// App
import {CompanyKindType} from '@shared/modules/Company/enums/CompanyKind';

export interface CompanyDetailsProfileFormType {
  companyId: string;
  kind: CompanyKindType;
}

const edit = withFragment(
  (company: CompanyModel) => ({
    companyId: company.id,
    kind: company.kind,
  }),
  gql`
    fragment CompanyDetailsProfileForm_edit on Company {
      id
      kind
    }
  `,
);

const toForm = ({companyId, kind}: CompanyDetailsProfileFormType) => ({
  companyId,
  kind,
});

const toMutation = ({companyId, kind}: CompanyDetailsProfileFormType) => ({
  companyId,
  kind,
});

const CompanyDetailsProfileForm = {
  edit,
  toForm,
  toMutation,
};

export default CompanyDetailsProfileForm;
