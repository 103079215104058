export interface SupermoveProductFormProps {
  supermoveProductId: number | null;
  companyId: number | null;
  kind: string;
  status: string;
  numberOfLicenses: number | null;

  // private
  isSetupComplete: boolean;
}

const _new = ({
  kind,
  status,
  numberOfLicenses,
  companyId,
}: {
  kind: string;
  status: string;
  numberOfLicenses?: number;
  companyId?: number;
}) => ({
  supermoveProductId: null,
  companyId: companyId || null,
  kind,
  status,
  numberOfLicenses: numberOfLicenses || null,

  // private
  isSetupComplete: false,
});

const toForm = (supermoveProductForm: SupermoveProductFormProps) => ({
  supermoveProductId: supermoveProductForm.supermoveProductId,
  companyId: supermoveProductForm.companyId,
  kind: supermoveProductForm.kind,
  status: supermoveProductForm.status,
  numberOfLicenses: supermoveProductForm.numberOfLicenses,

  // private
  isSetupComplete: supermoveProductForm.isSetupComplete,
});

const toMutation = (supermoveProductForm: SupermoveProductFormProps) => ({
  supermoveProductId: supermoveProductForm.supermoveProductId,
  companyId: supermoveProductForm.companyId,
  kind: supermoveProductForm.kind,
  status: supermoveProductForm.status,
  numberOfLicenses: supermoveProductForm.numberOfLicenses,
});

const SupermoveProductForm = {
  new: _new,
  toForm,
  toMutation,
};

export default SupermoveProductForm;
