// Libraries
import React from 'react';

// Supermove
import {Loading, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery, useToggle} from '@supermove/hooks';
import {CompanyModel} from '@supermove/models';

// App
import EditPanel from '@shared/design/components/Panel/EditPanel';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import CompanyDetailsExternalLinksForm from '@shared/modules/Company/forms/CompanyDetailsExternalLinksForm';
import CompanyDetailsHistoryForm from '@shared/modules/Company/forms/CompanyDetailsHistoryForm';
import CompanyDetailsLocationForm from '@shared/modules/Company/forms/CompanyDetailsLocationForm';
import CompanyDetailsProfileForm from '@shared/modules/Company/forms/CompanyDetailsProfileForm';
import CompanyDetailsSizeForm from '@shared/modules/Company/forms/CompanyDetailsSizeForm';
import useUpdateCompanyDetailsExternalLinksMutation from '@shared/modules/Company/hooks/useUpdateCompanyDetailsExternalLinksMutation';
import useUpdateCompanyDetailsHistoryMutation from '@shared/modules/Company/hooks/useUpdateCompanyDetailsHistoryMutation';
import useUpdateCompanyDetailsLocationMutation from '@shared/modules/Company/hooks/useUpdateCompanyDetailsLocationMutation';
import useUpdateCompanyDetailsProfileMutation from '@shared/modules/Company/hooks/useUpdateCompanyDetailsProfileMutation';
import useUpdateCompanyDetailsSizeMutation from '@shared/modules/Company/hooks/useUpdateCompanyDetailsSizeMutation';
import CompanyDetailsPage from 'modules/Company/CompanyDetails/CompanyDetailsPage';
import CompanyDetailsExternalLinksPanel from 'modules/Company/CompanyDetails/components/CompanyDetailsExternalLinksPanel';
import CompanyDetailsExternalLinksPanelEdit from 'modules/Company/CompanyDetails/components/CompanyDetailsExternalLinksPanelEdit';
import CompanyDetailsHistoryPanel from 'modules/Company/CompanyDetails/components/CompanyDetailsHistoryPanel';
import CompanyDetailsHistoryPanelEdit from 'modules/Company/CompanyDetails/components/CompanyDetailsHistoryPanelEdit';
import CompanyDetailsLocationPanel from 'modules/Company/CompanyDetails/components/CompanyDetailsLocationPanel';
import CompanyDetailsLocationPanelEdit from 'modules/Company/CompanyDetails/components/CompanyDetailsLocationPanelEdit';
import CompanyDetailsProfilePanel from 'modules/Company/CompanyDetails/components/CompanyDetailsProfilePanel';
import CompanyDetailsProfilePanelEdit from 'modules/Company/CompanyDetails/components/CompanyDetailsProfilePanelEdit';
import CompanyDetailsSizePanel from 'modules/Company/CompanyDetails/components/CompanyDetailsSizePanel';
import CompanyDetailsSizePanelEdit from 'modules/Company/CompanyDetails/components/CompanyDetailsSizePanelEdit';

const CompanyDetailsProfile = ({
  index,
  company,
  refetch,
}: {
  index: number;
  company: CompanyModel;
  refetch: () => void;
}) => {
  const editCompanyDetailsProfileToggle = useToggle();
  const companyDetailsProfileForm = CompanyDetailsProfileForm.edit(company);
  const {form, handleSubmit, submitting} = useUpdateCompanyDetailsProfileMutation({
    companyDetailsProfileForm,
    onSuccess: () => {
      editCompanyDetailsProfileToggle.handleToggleOff();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <EditPanel
      title={'Profile'}
      index={index}
      width={null}
      bodyComponentProps={{company}}
      BodyComponent={CompanyDetailsProfilePanel}
      editBodyComponentProps={{form, company}}
      EditBodyComponent={CompanyDetailsProfilePanelEdit}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={editCompanyDetailsProfileToggle.isOn}
      handleEdit={editCompanyDetailsProfileToggle.handleToggleOn}
      handleClose={editCompanyDetailsProfileToggle.handleToggleOff}
    />
  );
};

const CompanyDetailsLocation = ({
  index,
  company,
  refetch,
}: {
  index: number;
  company: CompanyModel;
  refetch: () => void;
}) => {
  const editCompanyDetailsLocationToggle = useToggle();
  const companyDetailsLocationForm = CompanyDetailsLocationForm.edit(company);
  const {form, handleSubmit, submitting} = useUpdateCompanyDetailsLocationMutation({
    companyDetailsLocationForm,
    onSuccess: () => {
      editCompanyDetailsLocationToggle.handleToggleOff();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <EditPanel
      title={'Location'}
      index={index}
      width={null}
      bodyComponentProps={{company}}
      BodyComponent={CompanyDetailsLocationPanel}
      editBodyComponentProps={{form}}
      EditBodyComponent={CompanyDetailsLocationPanelEdit}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={editCompanyDetailsLocationToggle.isOn}
      handleEdit={editCompanyDetailsLocationToggle.handleToggleOn}
      handleClose={editCompanyDetailsLocationToggle.handleToggleOff}
    />
  );
};

const CompanyDetailsSize = ({
  index,
  company,
  refetch,
}: {
  index: number;
  company: CompanyModel;
  refetch: () => void;
}) => {
  const editCompanyDetailsSizeToggle = useToggle();
  const companyDetailsSizeForm = CompanyDetailsSizeForm.edit(company);
  const {form, handleSubmit, submitting} = useUpdateCompanyDetailsSizeMutation({
    companyDetailsSizeForm,
    onSuccess: () => {
      editCompanyDetailsSizeToggle.handleToggleOff();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <EditPanel
      title={'Size'}
      index={index}
      width={null}
      bodyComponentProps={{company}}
      BodyComponent={CompanyDetailsSizePanel}
      editBodyComponentProps={{form, company}}
      EditBodyComponent={CompanyDetailsSizePanelEdit}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={editCompanyDetailsSizeToggle.isOn}
      handleEdit={editCompanyDetailsSizeToggle.handleToggleOn}
      handleClose={editCompanyDetailsSizeToggle.handleToggleOff}
    />
  );
};

const CompanyDetailsExternalLinks = ({
  index,
  company,
  refetch,
}: {
  index: number;
  company: CompanyModel;
  refetch: () => void;
}) => {
  const editCompanyDetailsExternalLinksToggle = useToggle();
  const companyDetailsExternalLinksForm = CompanyDetailsExternalLinksForm.edit(company);
  const {form, handleSubmit, submitting} = useUpdateCompanyDetailsExternalLinksMutation({
    companyDetailsExternalLinksForm,
    onSuccess: () => {
      editCompanyDetailsExternalLinksToggle.handleToggleOff();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <EditPanel
      title={'External Links'}
      index={index}
      width={null}
      bodyComponentProps={{company}}
      BodyComponent={CompanyDetailsExternalLinksPanel}
      editBodyComponentProps={{form}}
      EditBodyComponent={CompanyDetailsExternalLinksPanelEdit}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={editCompanyDetailsExternalLinksToggle.isOn}
      handleEdit={editCompanyDetailsExternalLinksToggle.handleToggleOn}
      handleClose={editCompanyDetailsExternalLinksToggle.handleToggleOff}
    />
  );
};

const CompanyDetailsHistory = ({
  index,
  company,
  refetch,
}: {
  index: number;
  company: CompanyModel;
  refetch: () => void;
}) => {
  const editCompanyDetailsHistoryToggle = useToggle();
  const companyDetailsHistoryForm = CompanyDetailsHistoryForm.edit(company);
  const {form, handleSubmit, submitting} = useUpdateCompanyDetailsHistoryMutation({
    companyDetailsHistoryForm,
    onSuccess: () => {
      editCompanyDetailsHistoryToggle.handleToggleOff();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <EditPanel
      title={'History'}
      index={index}
      width={null}
      bodyComponentProps={{company}}
      BodyComponent={CompanyDetailsHistoryPanel}
      editBodyComponentProps={{form, company}}
      EditBodyComponent={CompanyDetailsHistoryPanelEdit}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={editCompanyDetailsHistoryToggle.isOn}
      handleEdit={editCompanyDetailsHistoryToggle.handleToggleOn}
      handleClose={editCompanyDetailsHistoryToggle.handleToggleOff}
    />
  );
};

const CompanyDetailsOverviewContent = ({
  company,
  refetch,
}: {
  company: CompanyModel;
  refetch: () => void;
}) => {
  return (
    <React.Fragment>
      <CompanyDetailsProfile index={0} company={company} refetch={refetch} />
      <Space height={24} />
      <CompanyDetailsLocation index={1} company={company} refetch={refetch} />
      <Space height={24} />
      <CompanyDetailsSize index={2} company={company} refetch={refetch} />
      <Space height={24} />
      <CompanyDetailsExternalLinks index={3} company={company} refetch={refetch} />
      <Space height={24} />
      <CompanyDetailsHistory index={4} company={company} refetch={refetch} />
    </React.Fragment>
  );
};

const CompanyDetailsOverviewPage = () => {
  const {params} = useNavigation();
  const {loading, data, refetch} = useQuery(CompanyDetailsOverviewPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {slug: params.slug},
  });

  return (
    <CompanyDetailsPage
      selectedLabel={'company/overview'}
      pageTitle={'Overview'}
      pageDescription={'Edit and manage essential company information and settings.'}
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => <CompanyDetailsOverviewContent company={data.company} refetch={refetch} />}
      </Loading>
    </CompanyDetailsPage>
  );
};

CompanyDetailsOverviewPage.query = gql`
  ${CompanyDetailsProfileForm.edit.fragment}
  ${CompanyDetailsLocationForm.edit.fragment}
  ${CompanyDetailsSizeForm.edit.fragment}
  ${CompanyDetailsExternalLinksForm.edit.fragment}
  ${CompanyDetailsHistoryForm.edit.fragment}
  ${CompanyDetailsProfilePanel.fragment}
  ${CompanyDetailsProfilePanelEdit.fragment}
  ${CompanyDetailsLocationPanel.fragment}
  ${CompanyDetailsSizePanel.fragment}
  ${CompanyDetailsSizePanelEdit.fragment}
  ${CompanyDetailsExternalLinksPanel.fragment}
  ${CompanyDetailsHistoryPanel.fragment}
  query CompanyDetailsOverviewPage($slug: String!) {
    ${gql.query}
    company(slug: $slug) {
      id
      ...CompanyDetailsProfileForm_edit
      ...CompanyDetailsLocationForm_edit
      ...CompanyDetailsSizeForm_edit
      ...CompanyDetailsExternalLinksForm_edit
      ...CompanyDetailsHistoryForm_edit
      ...CompanyDetailsProfilePanel
      ...CompanyDetailsProfilePanelEdit
      ...CompanyDetailsLocationPanel
      ...CompanyDetailsSizePanel
      ...CompanyDetailsSizePanelEdit
      ...CompanyDetailsExternalLinksPanel
      ...CompanyDetailsHistoryPanel
    }
  }
`;

export default CompanyDetailsOverviewPage;
