// Supermove
import {generated, gqlTyped as gql} from '@supermove/graphql';
import {useForm, useTypedFormMutation as useFormMutation} from '@supermove/hooks';

// App
import DashboardTagForm from '@shared/modules/DashboardTag/forms/DashboardTagForm';

const useCreateDashboardTagMutation = ({
  dashboardTagForm,
  onSuccess,
  onError,
}: {
  dashboardTagForm: generated.DashboardTagForm;
  onSuccess: (response: any) => void;
  onError: (error: any) => void;
}) => {
  const form = useForm({
    initialValues: {
      dashboardTagForm: DashboardTagForm.toForm(dashboardTagForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateDashboardTagMutation.mutation,
    variables: {
      dashboardTagForm: DashboardTagForm.toMutation(form.values.dashboardTagForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateDashboardTagMutation.mutation = gql(`
  mutation useCreateDashboardTagMutation($dashboardTagForm: DashboardTagForm!) {
    response: createDashboardTag(dashboardTagForm: $dashboardTagForm) {
      errors {
        field
        message
      }
      dashboardTag {
        id
      }
    }
  }
`);

export default useCreateDashboardTagMutation;
