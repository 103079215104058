// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {generated, gqlTyped as gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App

const Row = Styled.View`
  flex-direction: row;
`;

const Container = Styled.ButtonV2`
  flex-direction: column;
`;

const OrganizationName = Styled.Text`
  ${Typography.Responsive.Body}
`;

const OrganizationSlug = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const OrganizationItem = ({
  organization,
  isSelected,
  handlePress,
}: {
  organization: generated.OrganizationItemFragment;
  isSelected: boolean;
  handlePress: () => void;
}) => {
  const responsive = useResponsive();

  return (
    <Container style={{paddingVertical: '8px'}} onPress={handlePress}>
      <Row style={{justifyContent: 'space-between'}}>
        <OrganizationName
          responsive={responsive}
          style={{
            color: isSelected ? colors.blue.hover : colors.gray.primary,
            fontWeight: isSelected ? '800' : '500',
          }}
        >
          {organization.name}
        </OrganizationName>
        {isSelected && <Icon color={colors.blue.hover} size={16} source={Icon.CircleCheck} />}
      </Row>
      <Space height={4} />
      <OrganizationSlug responsive={responsive}>{organization.slug}</OrganizationSlug>
    </Container>
  );
};

export const OrganizationItemFragment = gql(`
  fragment OrganizationItem on Organization {
    id
    name
    slug
    exploUserGroupToken
  }
`);

export default OrganizationItem;
