// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Icon, Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useNavigation, useQuery, useModal, useDrawer} from '@supermove/hooks';
import {OrganizationModel, SupermoveProductModel} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';
import {Datetime, List} from '@supermove/utils';

// App
import Badge from '@shared/design/components/Badge';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import Table from '@shared/design/components/Table';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import SupermoveProductKind from '@shared/modules/SupermoveProduct/enums/SupermoveProductKind';
import SupermoveProductStatus from '@shared/modules/SupermoveProduct/enums/SupermoveProductStatus';
import CompanyDetailsPage from 'modules/Company/CompanyDetails/CompanyDetailsPage';
import AddSalesAppToCompanyModal from 'modules/Company/CompanyDetails/components/AddSalesAppToCompanyModal';
import UpdateSupermoveProductSettingsDrawer from 'modules/Company/CompanyDetails/components/UpdateSupermoveProductSettingsDrawer';

const BodyText = Styled.Text`
  ${Typography.Responsive.Body}
`;

// ////////////////////////////////////////////////////////////////////
// FEATURE FLAG REMOVAL: ADMIN_MANAGE_COMPANY
// ////////////////////////////////////////////////////////////////////

const SupermoveProductPanelBody = ({description, status}: any) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <BodyText responsive={responsive}>{description}</BodyText>
      <Space height={16} />
      <Badge
        label={_.startCase(_.toLower(status)) || 'Inactive'}
        size={'SMALL'}
        color={
          status === SupermoveProductStatus.ACTIVE ? colors.green.status : colors.gray.secondary
        }
        width={70}
      />
    </React.Fragment>
  );
};

interface SalesAppActionButtonProps {
  product: any;
  organization: any;
  refetch: () => void;
}

const SalesAppActionButton = ({product, organization, refetch}: SalesAppActionButtonProps) => {
  const {navigator, params} = useNavigation();
  const addSalesAppToCompanyModal = useModal({name: 'Add Sales App To Company Modal'});
  const hasActiveCrewAppProduct = product && product.status === SupermoveProductStatus.ACTIVE;
  return (
    <React.Fragment>
      {hasActiveCrewAppProduct ? (
        <TertiaryButton
          isResponsive
          text={'View'}
          onPress={() =>
            navigator.navigate('CompanyDetailsAiSalesCopilotGeneralPage', {slug: params.slug})
          }
        />
      ) : (
        <TertiaryButton
          isResponsive
          text={'Add Product'}
          iconLeft={Icon.Plus}
          onPress={addSalesAppToCompanyModal.handleOpen}
        />
      )}
      <AddSalesAppToCompanyModal
        key={addSalesAppToCompanyModal.key}
        isOpen={addSalesAppToCompanyModal.isOpen}
        handleClose={addSalesAppToCompanyModal.handleClose}
        refetch={refetch}
        organization={organization}
      />
    </React.Fragment>
  );
};

interface SupermoveProductPanelProps {
  title: string;
  description: string;
  status: string;
  ActionButtonComponent?: React.ReactNode;
  actionButtonComponentProps?: any;
}

const SupermoveProductPanel = ({
  title,
  description,
  status,
  ActionButtonComponent,
  actionButtonComponentProps,
}: SupermoveProductPanelProps) => {
  return (
    <ActionPanel
      title={title}
      BodyComponent={SupermoveProductPanelBody}
      bodyComponentProps={{
        description,
        status,
      }}
      // @ts-expect-error TS(2322): Type 'ReactNode' is not assignable to type 'Compon... Remove this comment to see the full error message
      ActionButtonComponent={ActionButtonComponent}
      actionButtonComponentProps={actionButtonComponentProps}
    />
  );
};

interface OrganizationQueryModel extends OrganizationModel {
  officeAppProduct: SupermoveProductModel;
  crewAppProduct: SupermoveProductModel;
  salesAppProduct: SupermoveProductModel;
}

const CompanyDetailsProductsPageContentDeprecated = ({
  organization,
  refetch,
}: {
  organization: OrganizationQueryModel;
  refetch: () => void;
}) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <SupermoveProductPanel
        title={'Office App'}
        description={
          'Main app for back office staff to use including sales, dispatch, operations, and accounting departments.'
        }
        status={organization.officeAppProduct?.status}
      />
      <Space height={responsive.desktop ? 16 : 0} />
      <SupermoveProductPanel
        title={'Crew App'}
        description={'Tablet app used in the field for documents, billing, payments, and photos.'}
        status={organization.crewAppProduct?.status}
      />
      <Space height={responsive.desktop ? 16 : 0} />
      <SupermoveProductPanel
        title={'Sales App'}
        description={
          'Sales tool for handling phone calls with AI transcriptions, summaries, and more.'
        }
        status={organization.salesAppProduct?.status}
        ActionButtonComponent={SalesAppActionButton}
        actionButtonComponentProps={{
          product: organization.salesAppProduct,
          organization,
          refetch,
        }}
      />
    </React.Fragment>
  );
};

// ////////////////////////////////////////////////////////////////////
// FEATURE FLAG REMOVAL BLOCK END
// ////////////////////////////////////////////////////////////////////

const getColumnDefinitions = ({
  organization,
  refetch,
  navigator,
  params,
}: {
  organization: OrganizationModel;
  refetch: () => void;
  navigator: any;
  params: any;
}) => {
  return [
    {
      headerLabel: 'App',
      flex: 10,
      cellText: (supermoveProduct: SupermoveProductModel) =>
        SupermoveProductKind.getLabel(supermoveProduct.kind),
    },
    {
      headerLabel: 'Status',
      flex: 10,
      cellComponent: (supermoveProduct: SupermoveProductModel) => (
        <Badge
          label={SupermoveProductStatus.getDisplay(supermoveProduct.status)}
          color={
            supermoveProduct.status === SupermoveProductStatus.ACTIVE
              ? colors.green.status
              : colors.gray.secondary
          }
          isResponsive
        />
      ),
    },
    {
      headerLabel: 'Deployed Date',
      flex: 10,
      cellText: (supermoveProduct: SupermoveProductModel) =>
        supermoveProduct.deployedDate
          ? Datetime.convertToDisplayDate(
              supermoveProduct.deployedDate,
              Datetime.DISPLAY_SHORT_DATE,
            )
          : null,
    },
    {
      headerLabel: 'Actions',
      flex: 1,
      minWidth: 50,
      cellStyle: {alignItems: 'center'},
      actions: (supermoveProduct: SupermoveProductModel) => [
        ...List.insertIf(supermoveProduct.status === SupermoveProductStatus.ACTIVE, {
          text: 'Edit',
          onPress: ({handleOpen}: any) => handleOpen(),
          actionHook: {
            hook: useDrawer,
            hookArgument: {
              name: `Update Supermove Product Settings: ${SupermoveProductKind.getLabel(supermoveProduct.kind)}`,
            },
            renderComponent: ({isOpen, handleClose}: any) => {
              return (
                <UpdateSupermoveProductSettingsDrawer
                  isOpen={isOpen}
                  handleClose={handleClose}
                  refetch={refetch}
                  supermoveProduct={supermoveProduct}
                />
              );
            },
          },
        }),
        ...List.insertIf(
          supermoveProduct.status === SupermoveProductStatus.INACTIVE &&
            supermoveProduct.kind === SupermoveProductKind.SALES,
          {
            text: 'Add',
            onPress: ({handleOpen}: any) => handleOpen(),
            actionHook: {
              hook: useModal,
              hookArgument: {
                name: 'Add Sales App To Company Modal',
              },
              renderComponent: ({isOpen, handleClose}: any) => {
                return (
                  <AddSalesAppToCompanyModal
                    isOpen={isOpen}
                    handleClose={handleClose}
                    refetch={refetch}
                    organization={organization}
                  />
                );
              },
            },
          },
        ),
        ...List.insertIf(
          supermoveProduct.status === SupermoveProductStatus.ACTIVE &&
            supermoveProduct.kind === SupermoveProductKind.SALES,
          {
            text: 'View',
            onPress: () =>
              navigator.navigate('CompanyDetailsAiSalesCopilotGeneralPage', {slug: params.slug}),
          },
        ),
      ],
    },
  ];
};

const createDefaultSupermoveProductItem = (kind: string) => ({
  id: `default-${kind}`,
  kind,
  status: SupermoveProductStatus.INACTIVE,
  deployedDate: null,
});

const getSupermoveProductItems = (
  officeApp: SupermoveProductModel | null,
  crewApp: SupermoveProductModel | null,
  salesApp: SupermoveProductModel | null,
) => {
  return [
    officeApp ?? createDefaultSupermoveProductItem(SupermoveProductKind.OFFICE),
    crewApp ?? createDefaultSupermoveProductItem(SupermoveProductKind.CREW),
    salesApp ?? createDefaultSupermoveProductItem(SupermoveProductKind.SALES),
  ];
};

// Needed to fix compatibility with the TableBuilder ItemType
interface SupermoveProductItem extends SupermoveProductModel {
  [key: string]: unknown;
}

const CompanyDetailsProductsPageContent = ({
  organization,
  refetch,
}: {
  organization: OrganizationQueryModel;
  refetch: () => void;
}) => {
  const {navigator, params} = useNavigation();
  const supermoveProducts = getSupermoveProductItems(
    organization.officeAppProduct,
    organization.crewAppProduct,
    organization.salesAppProduct,
  ) as SupermoveProductItem[];

  return (
    <Table
      items={supermoveProducts}
      itemKey={'id'}
      columnDefinitions={getColumnDefinitions({organization, refetch, navigator, params})}
      hasBorder
    />
  );
};

const CompanyDetailsProductsPage = () => {
  const {params} = useNavigation();
  const {loading, data, refetch} = useQuery(CompanyDetailsProductsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {slug: params.slug},
  });

  return (
    <CompanyDetailsPage
      selectedLabel={'company/products'}
      pageTitle={'Products'}
      pageDescription={'Add and configure Supermove products for this company.'}
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          const {organization} = data;
          const {isEnabledAdminManageCompany} = organization.features;

          return isEnabledAdminManageCompany ? (
            <CompanyDetailsProductsPageContent organization={organization} refetch={refetch} />
          ) : (
            <CompanyDetailsProductsPageContentDeprecated
              organization={organization}
              refetch={refetch}
            />
          );
        }}
      </Loading>
    </CompanyDetailsPage>
  );
};

CompanyDetailsProductsPage.query = gql`
  ${AddSalesAppToCompanyModal.fragment}
  ${UpdateSupermoveProductSettingsDrawer.fragment}

  query CompanyDetailsProductsPage($slug: String!) {
    ${gql.query}
    organization(slug: $slug) {
      id
      features {
        isEnabledAdminManageCompany: isEnabled(feature: "ADMIN_MANAGE_COMPANY")
      }
      officeAppProduct: supermoveProduct(kind: "OFFICE") {
        id
        kind
        status
        deployedDate
        ...UpdateSupermoveProductSettingsDrawer
      }
      crewAppProduct: supermoveProduct(kind: "CREW") {
        id
        kind
        status
        deployedDate
        ...UpdateSupermoveProductSettingsDrawer
      }
      salesAppProduct: supermoveProduct(kind: "SALES") {
        id
        kind
        status
        deployedDate
        ...UpdateSupermoveProductSettingsDrawer
      }
      ...AddSalesAppToCompanyModal
    }
  }
`;

export default CompanyDetailsProductsPage;
