// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {generated, gqlTyped as gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import Badge from '@shared/design/components/Badge';
import FieldValue from '@shared/design/components/Field/FieldValue';
import PanelComponent from '@shared/design/components/Panel';
import Tag from '@shared/design/components/Tag';
import DashboardStatus, {
  DashboardStatusType,
} from '@shared/modules/GlobalDashboard/enums/DashboardStatus';

const TagsContainer = Styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;

const VariableContainer = Styled.Text`
  background-color: ${colors.gray.border};
  border: 1px solid ${colors.gray.secondary};
  border-radius: 4px;
  padding-horizontal: 12px;
  padding-vertical: 8px;
  flex-direction: row;
`;

const PageContainer = Styled.View`
  background-color: ${colors.gray.background};
`;

const convertStatusToBadgeProps = (status: DashboardStatusType) => {
  switch (status) {
    case 'DRAFT':
      return {
        iconLeft: Icon.EyeSlash,
        color: colors.yellow.status,
      };
    case 'PUBLISHED':
      return {
        iconLeft: Icon.Eye,
        color: colors.green.status,
      };
    case 'ARCHIVED':
      return {
        iconLeft: Icon.Archive,
        color: colors.gray.secondary,
      };
  }
};

interface GlobalDashboardDetailsPageSettingsPanelProps {
  globalDashboard: generated.GlobalDashboardDetailsPageSettingsPanelFragment;
}

const GlobalDashboardDetailsPageSettingsPanel = ({
  globalDashboard,
}: GlobalDashboardDetailsPageSettingsPanelProps) => {
  const badgeProps = convertStatusToBadgeProps(globalDashboard.status as DashboardStatusType);
  return (
    <PageContainer>
      <PanelComponent>
        <PanelComponent.Header>
          <PanelComponent.HeaderText>Report Settings</PanelComponent.HeaderText>
        </PanelComponent.Header>
        <PanelComponent.Body>
          <FieldValue label={'Status'} />
          <Badge
            iconLeft={badgeProps.iconLeft}
            color={badgeProps.color}
            label={DashboardStatus.getLabel(globalDashboard.status as DashboardStatusType)}
            style={{width: 'fit-content'}}
          />
          <Space height={16} />
          <FieldValue label={'Report Name'} value={globalDashboard.name} isResponsive />
          <Space height={16} />
          <FieldValue
            label={'Category'}
            value={globalDashboard.dashboardCategory?.name}
            isResponsive
          />
          <Space height={16} />
          <FieldValue
            label={'Explo ID'}
            value={globalDashboard.exploDashboardEmbedId}
            link={`http://app.explo.co/embed_id/${globalDashboard.exploDashboardEmbedId}`}
            newTab
            isResponsive
          />
          <Space height={16} />
          <FieldValue label={'Explo Variables'} isResponsive />
          <VariableContainer>
            <pre style={{margin: 0}}>
              {JSON.stringify(JSON.parse(globalDashboard.exploVariables || '{}'), null, 2)}
            </pre>
          </VariableContainer>
          <Space height={16} />
          <FieldValue
            label={'Description'}
            empty='None'
            value={globalDashboard.description}
            isResponsive
          />
          <Space height={16} />
          <FieldValue
            label={'Tags'}
            isResponsive
            empty={(globalDashboard.dashboardTags || []).length === 0 ? 'None' : undefined}
            value={(globalDashboard.dashboardTags || []).length > 0 ? '' : undefined}
          />
          <TagsContainer>
            {(globalDashboard.dashboardTags || []).map((dashboardTag) => (
              <React.Fragment key={dashboardTag?.tag?.id}>
                <Tag label={dashboardTag?.tag?.name} />
                <Space width={8} />
              </React.Fragment>
            ))}
          </TagsContainer>
          <Space height={16} />
          <FieldValue
            label={'Link to Help Article'}
            empty='None'
            link={globalDashboard.helpArticleUrl}
            value={globalDashboard.helpArticleUrl}
            isResponsive
          />
          <Space height={16} />
          <FieldValue
            label={'Add to All New Companies'}
            value={globalDashboard.isDefault ? 'Yes' : 'No'}
            isResponsive
          />
        </PanelComponent.Body>
      </PanelComponent>
    </PageContainer>
  );
};

const GlobalDashboardDetailsPageSettingsPanelFragment = gql(`
  fragment GlobalDashboardDetailsPageSettingsPanel on GlobalDashboard {
    id
    name
    status
    description
    isDefault
    helpArticleUrl
    isDefault
    exploVariables
    exploDashboardEmbedId
    dashboardCategory {
      id
      name
    }
    dashboardTags {
      tag {
        id
        name
      }
    }
  }
`);

export default GlobalDashboardDetailsPageSettingsPanel;
