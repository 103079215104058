// Libraries
import _ from 'lodash';
import React, {useEffect} from 'react';

// Supermove
import {Icon, Loading, Popover, Space, Styled} from '@supermove/components';
import {generated, gqlTyped as gql, useTypedQuery as useQuery} from '@supermove/graphql';
import {usePopover, useResponsive, useState} from '@supermove/hooks';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownInputComponent from '@shared/design/components/MultiDropdownInput';
import PopoverFilter from '@shared/modules/App/components/PopoverFilter';
import {GlobalDashboardsPageUrlFilters} from 'modules/Dashboards/components/types';

const ContentContainer = Styled.View`
`;

interface GlobalDashboardFilterProps {
  urlFilters: GlobalDashboardsPageUrlFilters;
  handleApply: (props: {
    categoryIds: string[];
    tagIds: string[];
    onlyDefault: true | undefined;
  }) => void;
}

const toArray = (value: string[] | string | undefined) => {
  return value ? _.flatten([value]) : [];
};

const GlobalDashboardFilter = ({urlFilters, handleApply}: GlobalDashboardFilterProps) => {
  const filterPopover = usePopover({
    name: 'Global dashboard filter modal popover',
  });

  const filtersCount = urlFilters.getFilterCount({
    filterKeys: ['categoryIds', 'tagIds', 'onlyDefault'],
  });

  return (
    <React.Fragment>
      <Popover.Content innerRef={filterPopover.ref}>
        <SecondaryButton
          text={`Filters ${filtersCount ? `(${filtersCount})` : ''}`}
          onPress={filterPopover.handleOpen}
          iconRight={Icon.AngleDown}
          iconSize={12}
          isSmall
          isWidthOfContainer
          isResponsive
        />
      </Popover.Content>
      <GlobalDashboardFilterPopover
        selectedCategoryIds={urlFilters.get('categoryIds')}
        selectedTagIds={urlFilters.get('tagIds')}
        isOnlyDefaultFilterEnabled={urlFilters.get('onlyDefault')}
        popover={filterPopover}
        handleApply={handleApply}
      />
    </React.Fragment>
  );
};

type GlobalDashboardFilterPopoverProps = {
  popover: ReturnType<typeof usePopover>;
  selectedCategoryIds: string[] | undefined;
  selectedTagIds: string[] | undefined;
  isOnlyDefaultFilterEnabled: boolean | undefined;
  handleApply: GlobalDashboardFilterProps['handleApply'];
};

const GlobalDashboardFilterPopover = ({
  popover,
  selectedCategoryIds,
  selectedTagIds,
  isOnlyDefaultFilterEnabled,
  handleApply,
}: GlobalDashboardFilterPopoverProps) => {
  const responsive = useResponsive();

  const [newSelectedCategoryIds, setNewSelectedCategoryIds] = useState(
    toArray(selectedCategoryIds),
  );
  const [newSelectedTagIds, setNewSelectedTagIds] = useState(toArray(selectedTagIds));
  const [isShowOnlyDefaultReports, setIsShowOnlyDefaultReports] = useState(
    !!isOnlyDefaultFilterEnabled,
  );

  useEffect(() => {
    if (popover.isOpen) {
      setNewSelectedCategoryIds(toArray(selectedCategoryIds));
      setNewSelectedTagIds(toArray(selectedTagIds));
      setIsShowOnlyDefaultReports(!!isOnlyDefaultFilterEnabled);
    }
  }, [popover.isOpen]);

  const filtersCount =
    (newSelectedCategoryIds.length ? 1 : 0) +
    (newSelectedTagIds.length ? 1 : 0) +
    (isShowOnlyDefaultReports ? 1 : 0);

  const {data, loading} = useQuery(GlobalDashboardFilterQuery, {
    fetchPolicy: 'cache-and-network',
    skip: !popover.isOpen,
  });

  return (
    <PopoverFilter
      activeFiltersCount={filtersCount}
      popover={popover}
      responsive={responsive}
      handleApply={() => {
        handleApply({
          categoryIds: newSelectedCategoryIds,
          tagIds: newSelectedTagIds,
          onlyDefault: isShowOnlyDefaultReports ? true : undefined,
        });

        popover.handleClose();
      }}
      handleClear={() => {
        setNewSelectedCategoryIds([]);
        setNewSelectedTagIds([]);
        setIsShowOnlyDefaultReports(false);

        handleApply({
          categoryIds: [],
          tagIds: [],
          onlyDefault: undefined,
        });

        popover.handleClose();
      }}
    >
      <Loading loading={loading || !data}>
        {() => (
          <ContentContainer style={responsive.desktop ? {} : {paddingHorizontal: 16}}>
            <GlobalDashboardFilterPopoverBody
              dashboardCategories={data?.dashboardCategories || []}
              dashboardTags={data?.dashboardTags || []}
              newSelectedCategoryIds={newSelectedCategoryIds}
              newSelectedTagIds={newSelectedTagIds}
              isShowOnlyDefaultReports={isShowOnlyDefaultReports}
              setNewSelectedCategoryIds={setNewSelectedCategoryIds}
              setNewSelectedTagIds={setNewSelectedTagIds}
              setIsShowOnlyDefaultReports={setIsShowOnlyDefaultReports}
            />
          </ContentContainer>
        )}
      </Loading>
    </PopoverFilter>
  );
};

type GlobalDashboardFilterPopoverBodyProps = {
  dashboardCategories: NonNullable<generated.GlobalDashboardFilterQuery['dashboardCategories']>;
  dashboardTags: NonNullable<generated.GlobalDashboardFilterQuery['dashboardTags']>;
  newSelectedCategoryIds: string[];
  newSelectedTagIds: string[];
  isShowOnlyDefaultReports: boolean;
  setNewSelectedCategoryIds: (value: string[]) => void;
  setNewSelectedTagIds: (value: string[]) => void;
  setIsShowOnlyDefaultReports: (value: boolean) => void;
};

const GlobalDashboardFilterPopoverBody = ({
  dashboardCategories,
  dashboardTags,
  newSelectedCategoryIds,
  newSelectedTagIds,
  isShowOnlyDefaultReports,
  setNewSelectedCategoryIds,
  setNewSelectedTagIds,
  setIsShowOnlyDefaultReports,
}: GlobalDashboardFilterPopoverBodyProps) => {
  const sortedCategoryOptions = dashboardCategories
    .map((category) => ({
      label: category?.name || '',
      value: category?.id || '',
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const sortedTagOptions = dashboardTags
    .map((tag) => ({
      label: tag?.name || '',
      value: tag?.id || '',
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <React.Fragment>
      <FieldInput
        index={0}
        component={MultiDropdownInputComponent}
        label={'Category'}
        input={{
          options: sortedCategoryOptions,
          name: 'Category',
          value: newSelectedCategoryIds,
          setFieldValue: (name: string, value: string[]) => setNewSelectedCategoryIds(value),
          placeholder: 'Select categories',
          style: {flex: 1},
          isResponsive: true,
        }}
      />
      <Space height={16} />
      <FieldInput
        index={1}
        component={MultiDropdownInputComponent}
        label={'Tag'}
        input={{
          options: sortedTagOptions,
          name: 'Tag',
          value: newSelectedTagIds,
          setFieldValue: (name: string, value: string[]) => setNewSelectedTagIds(value),
          placeholder: 'Select tags',
          style: {flex: 1},
          isResponsive: true,
        }}
      />
      <Space height={16} />
      <Checkbox
        isChecked={isShowOnlyDefaultReports}
        handleToggle={() => setIsShowOnlyDefaultReports(!isShowOnlyDefaultReports)}
        label={'Only show default reports'}
        isResponsive
      />
      <Space height={16} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
const GlobalDashboardFilterQuery = gql(`
  query GlobalDashboardFilter {
    dashboardCategories {
      id
      name
    }
    dashboardTags {
      id
      name
    }
  }
`);

export default GlobalDashboardFilter;
