const ACTIVE = 'ACTIVE';
const INACTIVE = 'INACTIVE';

const SUPERMOVE_PRODUCT_STATUS_OPTIONS = [ACTIVE, INACTIVE] as const;

export type SupermoveProductStatusType = (typeof SUPERMOVE_PRODUCT_STATUS_OPTIONS)[number];

const getDisplay = (status: SupermoveProductStatusType) => {
  switch (status) {
    case ACTIVE:
      return 'Active';
    case INACTIVE:
      return 'Inactive';
    default:
      return status;
  }
};

const SupermoveProductStatus = {
  ACTIVE,
  INACTIVE,

  getDisplay,
};

export default SupermoveProductStatus;
