// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form} from '@supermove/hooks';
import {CompanyModel} from '@supermove/models';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import CompanySize from '@shared/modules/Company/enums/CompanySize';
import {CompanyDetailsSizeFormType} from '@shared/modules/Company/forms/CompanyDetailsSizeForm';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

type FormType = Form<{
  companyDetailsSizeForm: CompanyDetailsSizeFormType;
}>;

const CompanyDetailsSizePanelEdit = ({form, company}: {form: FormType; company: CompanyModel}) => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <FieldInput
            {...form}
            label={'Number of Trucks'}
            name={'companyDetailsSizeForm.contractedTruckQuantity'}
            input={{
              placeholder: 'Enter number of trucks',
              keyboardType: 'numeric',
            }}
            isResponsive
          />
        </Column>
        <Space width={20} />
        <Column>
          <FieldInput
            {...form}
            label={'Size'}
            input={{
              disabled: true,
              value: CompanySize.getLabel(company.size),
            }}
            isResponsive
          />
        </Column>
      </Row>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompanyDetailsSizePanelEdit.fragment = gql`
  fragment CompanyDetailsSizePanelEdit on Company {
    id
    size
  }
`;

export default CompanyDetailsSizePanelEdit;
