// Libraries

// Supermove
import {gql} from '@supermove/graphql';
import {List} from '@supermove/utils';

// App
import {NavigationItem} from 'core/NavigationItems';

/**
 * Retrieves Company Details navigation items.
 * @see PR #26794 for implementation example with an argument for displaying items based on feature flags
 */
const getItems = ({
  isEnabledAdminManageCompany = false,
}: {
  isEnabledAdminManageCompany?: boolean;
}): NavigationItem[] => {
  return [
    {
      label: 'Company',
      value: 'COMPANY',
      valueKey: 'section',
      items: [
        ...List.insertIf(isEnabledAdminManageCompany, {
          label: 'Overview',
          value: 'CompanyDetailsOverviewPage',
          valueKey: 'route' as const,
        }),
        {
          label: 'Products',
          value: 'CompanyDetailsProductsPage',
          valueKey: 'route',
        },
      ],
    },
    {
      label: 'Modules',
      value: 'MODULES',
      valueKey: 'section',
      items: [
        {
          label: 'Calendars',
          value: 'CompanyModulesCalendarsPage',
          valueKey: 'route',
        },
        {
          label: 'Communication',
          value: 'CompanyModulesCommunicationPage',
          valueKey: 'route',
        },
        {
          label: 'Equipment & Materials',
          value: 'CompanyModulesEquipmentAndMaterialsPage',
          valueKey: 'route',
        },
        {
          label: 'Inventory',
          value: 'CompanyModulesInventoryPage',
          valueKey: 'route',
        },
        {
          label: 'Payments',
          value: 'CompanyModulesPaymentsPage',
          valueKey: 'route',
        },
        {
          label: 'Storage',
          value: 'CompanyModulesStoragePage',
          valueKey: 'route',
        },
        {
          label: 'Timesheets',
          value: 'CompanyModulesTimesheetsPage',
          valueKey: 'route',
        },
      ],
    },
    {
      label: 'AI Sales Copilot',
      value: 'AI_SALES_COPILOT',
      valueKey: 'section',
      items: [
        {
          label: 'General',
          value: 'CompanyDetailsAiSalesCopilotGeneralPage',
          valueKey: 'route',
        },
      ],
    },
  ];
};

const CompanyDetailsNavigation = {
  getItems,
  fragment: gql`
    fragment CompanyDetailsNavigation on Organization {
      id
      features {
        isEnabledAdminManageCompany: isEnabled(feature: "ADMIN_MANAGE_COMPANY")
      }
    }
  `,
};

export default CompanyDetailsNavigation;
