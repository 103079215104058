// Supermove
import {gqlTyped as gql} from '@supermove/graphql';
import {useForm, useTypedFormMutation} from '@supermove/hooks';

// App
import GlobalDashboardForm from '@shared/modules/GlobalDashboard/forms/GlobalDashboardForm';

const useUpdateGlobalDashboardMutation = ({
  globalDashboardForm,
  onSuccess,
  onError,
}: {
  globalDashboardForm: ReturnType<typeof GlobalDashboardForm.edit>;
  onSuccess: (response: any) => void;
  onError: (error: any) => void;
}) => {
  const form = useForm({
    initialValues: {
      globalDashboardForm: GlobalDashboardForm.toForm(globalDashboardForm),
    },
    enableReinitialize: true,
  });

  const {submitting, handleSubmit} = useTypedFormMutation({
    form,
    mutation: useUpdateGlobalDashboardMutation.mutation,
    variables: {
      globalDashboardForm: GlobalDashboardForm.toMutation(form.values.globalDashboardForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateGlobalDashboardMutation.mutation = gql(`
  mutation useUpdateGlobalDashboardMutation($globalDashboardForm: GlobalDashboardForm!) {
    response: updateGlobalDashboard(globalDashboardForm: $globalDashboardForm) {
      errors {
        field
        message
      }
      globalDashboard {
        id
        uuid
        name
      }
    }
  }
`);

export default useUpdateGlobalDashboardMutation;
