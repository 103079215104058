// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {CompanyModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

export interface CompanyDetailsSizeFormType {
  companyId: string;
  contractedTruckQuantity: number | string;
}

const edit = withFragment(
  (company: CompanyModel) => ({
    companyId: company.id,
    contractedTruckQuantity: company.contractedTruckQuantity,
  }),
  gql`
    fragment CompanyDetailsSizeForm_edit on Company {
      id
      contractedTruckQuantity
    }
  `,
);

const toForm = ({companyId, contractedTruckQuantity}: CompanyDetailsSizeFormType) => ({
  companyId,
  contractedTruckQuantity: _.toString(contractedTruckQuantity),
});

const toMutation = ({companyId, contractedTruckQuantity}: CompanyDetailsSizeFormType) => ({
  companyId,
  contractedTruckQuantity: _.toNumber(contractedTruckQuantity),
});

const CompanyDetailsSizeForm = {
  edit,
  toForm,
  toMutation,
};

export default CompanyDetailsSizeForm;
