// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// TODO(Hammad): We seem to have a few <Line> components; investigate and replace old components with this shared component

const LineView = Styled.View`
  height: 1px;
  background-color: ${({color}: {color: string}) => color};
`;

const Line = ({
  color = colors.gray.border,
  style,
}: {
  color?: string;
  style?: React.CSSProperties;
}) => {
  return <LineView color={color} style={style} />;
};

export default Line;
