interface ChatCompletionForm {
  inputSystemMessage: string;
  inputUserMessage: string;
  openAiModelIdentifier: string;
  callFunctionDefinition: string;
}

const _new = () => {
  return {
    inputSystemMessage: '',
    inputUserMessage: '',
    openAiModelIdentifier: 'gpt-4o',
    callFunctionDefinition: '',
  };
};

const toForm = ({
  inputSystemMessage,
  inputUserMessage,
  openAiModelIdentifier,
  callFunctionDefinition,
}: ChatCompletionForm) => ({
  inputSystemMessage,
  inputUserMessage,
  openAiModelIdentifier,
  callFunctionDefinition,
});

const toMutation = ({
  inputSystemMessage,
  inputUserMessage,
  openAiModelIdentifier,
  callFunctionDefinition,
}: ChatCompletionForm) => ({
  // remove all newlines here
  inputSystemMessage,
  inputUserMessage,
  openAiModelIdentifier,
  // Remove all trailing commas
  // eslint-disable-next-line
  callFunctionDefinition: callFunctionDefinition.replace(/,(?=\s*?[\}\]])/g, ''),
});

const ChatCompletionForm = {
  toForm,
  toMutation,
  new: _new,
};

export default ChatCompletionForm;
