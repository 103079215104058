// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Badge from '@shared/design/components/Badge';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import CreateCompanyStepKinds from '@shared/modules/Company/enums/CreateCompanyStepKinds';
import SupermoveProductKind from '@shared/modules/SupermoveProduct/enums/SupermoveProductKind';

interface ResponsiveType {
  desktop: boolean;
  tablet: boolean;
  mobile: boolean;
}

const Row = Styled.View`
  flex-direction: row;
`;

const Container = Styled.View`
  height: 100%;
`;

const Panel = ({
  title,
  BodyComponent,
  handleEdit,
}: {
  title: string;
  BodyComponent: React.ComponentType<any>;
  handleEdit: () => void;
}) => {
  return (
    <ActionPanel
      title={title}
      ActionButtonComponent={() => (
        <EditPanel.EditButton handleEdit={handleEdit} isEditing={false} isDisabled={false} />
      )}
      BodyComponent={BodyComponent}
    />
  );
};

const CompanyDetailsPanel = ({
  form,
  field,
  setCurrentStepKind,
  responsive,
}: {
  form: any;
  field: string;
  setCurrentStepKind: (kind: string) => void;
  responsive: ResponsiveType;
}) => {
  return (
    <Panel
      title={CreateCompanyStepKinds.getStepTitle(CreateCompanyStepKinds.COMPANY_DETAILS)}
      handleEdit={() => setCurrentStepKind(CreateCompanyStepKinds.COMPANY_DETAILS)}
      BodyComponent={() => {
        return (
          <React.Fragment>
            <ActionPanel.LabelText responsive={responsive}>Company Name</ActionPanel.LabelText>
            <Space height={8} />
            <ActionPanel.Text responsive={responsive}>
              {_.get(form.values, `${field}.name`)}
            </ActionPanel.Text>
            <Space height={16} />
            <ActionPanel.LabelText responsive={responsive}>Identifier</ActionPanel.LabelText>
            <Space height={8} />
            <ActionPanel.Text responsive={responsive}>
              {_.get(form.values, `${field}.identifier`)}
            </ActionPanel.Text>
          </React.Fragment>
        );
      }}
    />
  );
};

const ProductsOverviewPanel = ({
  form,
  field,
  setCurrentStepKind,
}: {
  form: any;
  field: string;
  setCurrentStepKind: (kind: string) => void;
}) => {
  const supermoveProductForms = _.get(form.values, `${field}.supermoveProductForms`, []);
  return (
    <Panel
      title={CreateCompanyStepKinds.getStepTitle(CreateCompanyStepKinds.PRODUCTS.OVERVIEW)}
      handleEdit={() => setCurrentStepKind(CreateCompanyStepKinds.PRODUCTS.OVERVIEW)}
      BodyComponent={() => {
        return (
          <Row style={{flexWrap: 'wrap'}}>
            {supermoveProductForms.map(({kind}: {kind: string}, index: number) => (
              <Row key={index}>
                <Badge
                  isResponsive
                  label={SupermoveProductKind.getLabel(kind)}
                  color={SupermoveProductKind.getColor(kind)}
                />
                <Space width={8} />
              </Row>
            ))}
          </Row>
        );
      }}
    />
  );
};

const SalesProductPanel = ({
  form,
  field,
  setCurrentStepKind,
  responsive,
}: {
  form: any;
  field: string;
  setCurrentStepKind: (kind: string) => void;
  responsive: ResponsiveType;
}) => {
  const supermoveProductForms = _.get(form.values, `${field}.supermoveProductForms`, []);
  const salesProductForm = _.find(supermoveProductForms, {kind: SupermoveProductKind.SALES});
  return (
    <Panel
      title={CreateCompanyStepKinds.getStepTitle(CreateCompanyStepKinds.PRODUCTS.SALES)}
      handleEdit={() => setCurrentStepKind(CreateCompanyStepKinds.PRODUCTS.SALES)}
      BodyComponent={() => {
        return (
          <React.Fragment>
            <ActionPanel.LabelText responsive={responsive}>
              Number of Licenses
            </ActionPanel.LabelText>
            <Space height={8} />
            <ActionPanel.Text responsive={responsive}>
              {salesProductForm?.numberOfLicenses}
            </ActionPanel.Text>
          </React.Fragment>
        );
      }}
    />
  );
};

const CreateCompanyReviewStep = ({
  form,
  field,
  setCurrentStepKind,
}: {
  form: any;
  field: string;
  setCurrentStepKind: (kind: string) => void;
}) => {
  const responsive = useResponsive();
  return (
    <Container style={responsive.desktop ? {} : {backgroundColor: colors.white}}>
      <CompanyDetailsPanel
        form={form}
        field={field}
        setCurrentStepKind={setCurrentStepKind}
        responsive={responsive}
      />
      <ProductsOverviewPanel form={form} field={field} setCurrentStepKind={setCurrentStepKind} />
      <SalesProductPanel
        form={form}
        field={field}
        setCurrentStepKind={setCurrentStepKind}
        responsive={responsive}
      />
    </Container>
  );
};

export default CreateCompanyReviewStep;
