// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CompanyDetailsLocationForm, {
  CompanyDetailsLocationFormType,
} from '@shared/modules/Company/forms/CompanyDetailsLocationForm';

const useUpdateCompanyDetailsLocationMutation = ({
  companyDetailsLocationForm,
  onSuccess,
  onError,
}: {
  companyDetailsLocationForm: CompanyDetailsLocationFormType;
  onSuccess: (response: unknown) => void;
  onError: (error: unknown) => void;
}) => {
  const form = useForm({
    initialValues: {
      companyDetailsLocationForm: CompanyDetailsLocationForm.toForm(companyDetailsLocationForm),
    },
    enableReinitialize: true,
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateCompanyDetailsLocationMutation.mutation,
    variables: {
      companyDetailsLocationForm: CompanyDetailsLocationForm.toMutation(
        form.values.companyDetailsLocationForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateCompanyDetailsLocationMutation.mutation = gql`
  mutation useUpdateCompanyDetailsLocationMutation($companyDetailsLocationForm: CompanyDetailsLocationForm!) {
    response: updateCompanyDetailsLocation(companyDetailsLocationForm: $companyDetailsLocationForm) {
      ${gql.errors}
      company {
        id
      }
    }
  }
`;

export default useUpdateCompanyDetailsLocationMutation;
