// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CompanyDetailsHistoryForm, {
  CompanyDetailsHistoryFormType,
} from '@shared/modules/Company/forms/CompanyDetailsHistoryForm';

const useUpdateCompanyDetailsHistoryMutation = ({
  companyDetailsHistoryForm,
  onSuccess,
  onError,
}: {
  companyDetailsHistoryForm: CompanyDetailsHistoryFormType;
  onSuccess: (response: unknown) => void;
  onError: (error: unknown) => void;
}) => {
  const form = useForm({
    initialValues: {
      companyDetailsHistoryForm: CompanyDetailsHistoryForm.toForm(companyDetailsHistoryForm),
    },
    enableReinitialize: true,
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateCompanyDetailsHistoryMutation.mutation,
    variables: {
      companyDetailsHistoryForm: CompanyDetailsHistoryForm.toMutation(
        form.values.companyDetailsHistoryForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateCompanyDetailsHistoryMutation.mutation = gql`
  mutation useUpdateCompanyDetailsHistoryMutation($companyDetailsHistoryForm: CompanyDetailsHistoryForm!) {
    response: updateCompanyDetailsHistory(companyDetailsHistoryForm: $companyDetailsHistoryForm) {
      ${gql.errors}
      company {
        id
      }
    }
  }
`;

export default useUpdateCompanyDetailsHistoryMutation;
