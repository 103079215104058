// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gqlTyped as gql, useTypedQuery as useQuery} from '@supermove/graphql';
import {existenceFilter} from '@supermove/utils';

// App
import DrawerWithClose from '@shared/design/components/Drawer/DrawerWithClose';
import ManageDashboardCategoriesList from 'modules/Dashboards/components/ManageDashboardCategoriesList';

const ManageDashboardCategoriesDrawer = ({
  isOpen,
  handleClose,
  refetch: refetchGlobalDashboards,
}: {
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
}) => {
  const {loading, data, refetch} = useQuery(ManageDashboardCategoriesDrawerQuery, {
    skip: !isOpen,
    fetchPolicy: 'cache-and-network',
  });

  const dashboardCategories = data?.dashboardCategories?.filter(existenceFilter) || [];

  return (
    <DrawerWithClose isOpen={isOpen} handleClose={handleClose} headerText={'Manage Categories'}>
      <Loading loading={!data || loading}>
        {() => (
          <ManageDashboardCategoriesList
            dashboardCategories={dashboardCategories}
            refetch={() => {
              refetchGlobalDashboards();
              refetch();
            }}
          />
        )}
      </Loading>
    </DrawerWithClose>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
const ManageDashboardCategoriesDrawerQuery = gql(`
  query ManageCategoriesDrawer {
    dashboardCategories {
        id
        ...ManageDashboardCategoriesList
    }
  }
`);

export default ManageDashboardCategoriesDrawer;
