// Libraries
import React, {useEffect} from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useInlineFormMutation, useState, useToast} from '@supermove/hooks';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import ManageGlobalDashboardCompaniesDrawerList, {
  OrganizationData,
} from 'modules/Dashboards/DashboardDetails/components/ManageGlobalDashboardCompaniesDrawerList';

type ResponseDataModel = {
  globalDashboard: {
    id: string;
    dashboards: {
      id: string;
      uuid: string;
      organizationId: number;
    }[];
  };
  activeOrganizations: ({
    id: string;
  } & OrganizationData)[];
};

interface ManageGlobalDashboardCompaniesDrawerProps {
  globalDashboardUuid: string;
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
}

const ManageGlobalDashboardCompaniesDrawer = ({
  globalDashboardUuid,
  isOpen,
  handleClose,
  refetch: refetchDashboard,
}: ManageGlobalDashboardCompaniesDrawerProps) => {
  const updateCompaniesSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Companies updated!',
  });

  const {loading, data, refetch} = useQuery<ResponseDataModel>(
    ManageGlobalDashboardCompaniesDrawer.query,
    {
      skip: !isOpen,
      fetchPolicy: 'network-only',
      variables: {
        globalDashboardUuid,
      },
    },
  );

  const [enabledOrganizationIds, setEnabledOrganizationIds] = useState<Set<number>>(
    new Set((data?.globalDashboard.dashboards || []).map((d) => d.organizationId)),
  );

  useEffect(() => {
    if (data) {
      setEnabledOrganizationIds(
        new Set((data?.globalDashboard.dashboards || []).map((d) => d.organizationId)),
      );
    }
  }, [data]);

  const {submitting, form, handleSubmit} = useInlineFormMutation({
    name: 'updateOrganizationsForGlobalDashboardForm',
    form: {
      globalDashboardId: data?.globalDashboard.id,
      organizationIdsToEnable: [],
      organizationIdsToDisable: [],
    },
    mutation: ManageGlobalDashboardCompaniesDrawer.mutation,
    onSuccess: () => {
      refetch();
      refetchDashboard();
      handleClose();
      updateCompaniesSuccessToast.handleToast();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  const handleToggle = ({
    organizationIds,
    isEnable,
  }: {
    organizationIds: number[];
    isEnable: boolean;
  }) => {
    setEnabledOrganizationIds((enabledOrganizationIds) => {
      if (isEnable) {
        return new Set([...Array.from(enabledOrganizationIds), ...organizationIds]);
      } else {
        return new Set(
          Array.from(enabledOrganizationIds).filter((id) => organizationIds.indexOf(id) === -1),
        );
      }
    });
  };

  const handleSave = () => {
    const initiallyEnabledOrganizationIds = (data?.globalDashboard.dashboards || []).map(
      (d) => d.organizationId,
    );
    const organizationIdsToEnable = Array.from(enabledOrganizationIds).filter(
      (id) => initiallyEnabledOrganizationIds.indexOf(id) === -1,
    );
    const organizationIdsToDisable = initiallyEnabledOrganizationIds.filter(
      (id) => Array.from(enabledOrganizationIds).indexOf(id) === -1,
    );

    form.values.updateOrganizationsForGlobalDashboardForm.globalDashboardId =
      data?.globalDashboard.id;
    form.values.updateOrganizationsForGlobalDashboardForm.organizationIdsToEnable =
      organizationIdsToEnable;
    form.values.updateOrganizationsForGlobalDashboardForm.organizationIdsToDisable =
      organizationIdsToDisable;

    setImmediate(handleSubmit);
  };

  return (
    <DrawerWithAction
      isOpen={isOpen}
      primaryActionText='Save'
      isSubmitting={submitting || loading}
      handleSubmit={handleSave}
      handleClose={handleClose}
      headerText='Manage Companies'
      width={500}
    >
      <Loading loading={!data || loading} as={PageLoadingIndicator}>
        {() =>
          data && (
            <ManageGlobalDashboardCompaniesDrawerList
              globalDashboardId={data.globalDashboard.id}
              activeOrganizations={data.activeOrganizations}
              enabledOrganizationIds={enabledOrganizationIds}
              handleToggle={handleToggle}
            />
          )
        }
      </Loading>
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ManageGlobalDashboardCompaniesDrawer.query = gql`
  ${ManageGlobalDashboardCompaniesDrawerList.fragment}
  query ManageGlobalDashboardCompaniesDrawer($globalDashboardUuid: String!) {
    ${gql.query}
    globalDashboard(uuid: $globalDashboardUuid) {
        id
        dashboards {
          id
          uuid
          organizationId
        }
    }
    activeOrganizations {
      id
      ...ManageGlobalDashboardCompaniesDrawerListFragment
    }
  }
`;

ManageGlobalDashboardCompaniesDrawer.mutation = gql`
  mutation ManageGlobalDashboardCompaniesDrawerMutation(
    $updateOrganizationsForGlobalDashboardForm: UpdateOrganizationsForGlobalDashboardForm!
  ) {
    response: updateOrganizationsForGlobalDashboard(
      updateOrganizationsForGlobalDashboardForm: $updateOrganizationsForGlobalDashboardForm
    ) {
      ${gql.errors}
      globalDashboard {
        id
      }
    }
  }
`;

export default ManageGlobalDashboardCompaniesDrawer;
