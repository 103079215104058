// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigation, useQuery, useResponsive, ResponsiveType} from '@supermove/hooks';
import {Organization, OrganizationModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Badge from '@shared/design/components/Badge';
import Button from '@shared/design/components/Button';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import Callout from '@shared/design/components/Callout';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import MultiDropdownInput from '@shared/design/components/MultiDropdownInput';
import Line from '@shared/modules/App/components/Line';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';
import ProjectTypesForm, {
  ProjectTypesFormType,
} from '@shared/modules/Project/forms/ProjectTypesForm';
import useUpdateProjectTypeFeaturesTimesheetsV2Mutation from '@shared/modules/Project/hooks/useUpdateProjectTypeFeaturesTimesheetsV2Mutation';
import SettingsSection from '@shared/modules/Settings/components/SettingsSection';
import SettingsSectionOption from '@shared/modules/Settings/components/SettingsSectionOption';
import CompanyDetailsPage from 'modules/Company/CompanyDetails/CompanyDetailsPage';

// Constants and Helper Methods
const SELECT_ALL = 'SELECT_ALL';
const UNSELECT_ALL = 'UNSELECT_ALL';

const getSelectAllIds = ({organization}: {organization: OrganizationModel}) => {
  const projectTypesNotYetEnabled = organization.projectTypes.filter(
    (projectType) => !projectType.features.timesheetsV2,
  );
  return projectTypesNotYetEnabled.map((projectType) => projectType.id);
};

// Components
const TitleContainer = Styled.View`
  flex-direction: row;
`;

const Title = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

const Column = Styled.View`
`;

const ProjectTypesRow = Styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;

const TimesheetsSettingsContainer = Styled.View`
  padding-horizontal: ${({responsive}: {responsive: ResponsiveType}) => (responsive.desktop ? 0 : 16)}px;
`;

const getProjectTypeOptions = ({
  organization,
  form,
}: {
  organization: OrganizationModel;
  form: ProjectTypesFormType;
}) => {
  const projectTypes = organization.projectTypes.filter(
    (projectType) => !projectType.features.timesheetsV2,
  );
  const selections = _.get(form.values, 'projectTypesForm.projectTypeIds');
  const isSelectedAll = selections.length === projectTypes.length;
  return [
    {
      label: (
        <React.Fragment>
          {isSelectedAll ? 'Unselect all' : 'Select all'}
          <Column>
            <Line style={{position: 'absolute', left: -12, bottom: -8, width: 400}} />
          </Column>
        </React.Fragment>
      ),
      value: isSelectedAll ? UNSELECT_ALL : SELECT_ALL,
    },
    ...projectTypes.map((projectType) => ({
      label: projectType.name,
      value: projectType.id,
    })),
  ];
};

const SelectProjectTypesModal = ({
  organization,
  isOpen,
  handleClose,
  refetch,
}: {
  organization: OrganizationModel;
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
}) => {
  const {form, submitting, handleSubmit} = useUpdateProjectTypeFeaturesTimesheetsV2Mutation({
    projectTypesForm: ProjectTypesForm.new(),
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <SmallModal isOpen={isOpen} handleClose={handleClose}>
      <SmallModal.HeaderText>Select Project Types</SmallModal.HeaderText>
      <Space height={16} />
      <FieldInput
        {...form}
        name={'projectTypesForm.projectTypeIds'}
        label={'Project Types'}
        component={MultiDropdownInput}
        input={{
          // @ts-expect-error TS(2741): Property 'projectTypeIds' is missing in type 'Form... Remove this comment to see the full error message
          options: getProjectTypeOptions({organization, form}),
          placeholder: 'Select project types',
          setFieldValue: form.setFieldValue,
          onChangeValue: (selections: any) => {
            if (_.last(selections) === SELECT_ALL) {
              form.setFieldValue(
                'projectTypesForm.projectTypeIds',
                getSelectAllIds({organization}),
              );
            }
            if (_.last(selections) === UNSELECT_ALL) {
              form.setFieldValue('projectTypesForm.projectTypeIds', []);
            }
          },
          style: {flex: 1},
          isPortaled: true,
        }}
      />
      <Space height={16} />
      <SmallModal.Footer isResponsive>
        <React.Fragment>
          <QuaternaryButton
            text={'Cancel'}
            textColor={colors.blue.interactive}
            onPress={handleClose}
            width={104}
          />
          <Space width={8} />
        </React.Fragment>
        <Button
          text={'Save'}
          onPress={handleSubmit}
          isSubmitting={submitting}
          iconLeft={Icon.Check}
          width={104}
        />
      </SmallModal.Footer>
    </SmallModal>
  );
};

const ActionComponent = ({
  organization,
  refetch,
}: {
  organization: OrganizationModel;
  refetch: () => void;
}) => {
  const projectTypesModal = useModal({name: 'Timesheets V2 Project Types Modal'});
  const enabledProjectTypes = organization.projectTypes.filter(
    (projectType) => projectType.features.timesheetsV2,
  );
  const isFullyEnabled = enabledProjectTypes.length === organization.projectTypes.length;

  return (
    <Column>
      {!isFullyEnabled && (
        <ProjectTypesRow>
          {enabledProjectTypes.map((projectType) => {
            return (
              <Badge
                key={projectType.id}
                label={projectType.name}
                iconLeft={Icon.Circle}
                iconColor={projectType.color}
                style={{marginRight: 8, marginBottom: 8}}
              />
            );
          })}
        </ProjectTypesRow>
      )}
      {_.some(enabledProjectTypes) && <Space height={4} />}
      {isFullyEnabled ? (
        <Callout text={'Timesheets V2 has been enabled for all project types.'} />
      ) : (
        <SecondaryButton
          text={'Select Project Types'}
          iconLeft={Icon.Pen}
          isSmall
          onPress={projectTypesModal.handleOpen}
        />
      )}
      <SelectProjectTypesModal
        key={projectTypesModal.key}
        isOpen={projectTypesModal.isOpen}
        handleClose={projectTypesModal.handleClose}
        organization={organization}
        refetch={refetch}
      />
    </Column>
  );
};

const TimesheetsSettings = ({
  responsive,
  primaryAndFranchiseOrganizations,
  refetch,
}: {
  responsive: ResponsiveType;
  primaryAndFranchiseOrganizations: OrganizationModel[];
  refetch: () => void;
}) => {
  return (
    <SettingsSection>
      {primaryAndFranchiseOrganizations.map((organization, index) => (
        <React.Fragment>
          {index > 0 && <Space height={24} />}
          <TitleContainer>
            <Title responsive={responsive}>{organization.name}</Title>
            <Space width={8} />
            <Badge isResponsive label={Organization.getBranchTypeForOrganization(organization)} />
          </TitleContainer>
          <Space height={8} />
          <SettingsSectionOption
            name={'Enable Timesheets V2'}
            description={
              'Enable the new timesheets experience that improves performance and includes the “Crew Hours” module for the selected project types.'
            }
            isEnabled
            isDisabledToggle
            ActionButtonComponent={ActionComponent}
            actionButtonComponentProps={{organization, primaryAndFranchiseOrganizations, refetch}}
          />
        </React.Fragment>
      ))}
    </SettingsSection>
  );
};

const CompanyModulesTimesheetsPage = () => {
  const {params} = useNavigation();
  const responsive = useResponsive();
  const {loading, data, refetch} = useQuery(CompanyModulesTimesheetsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {slug: params.slug, projectTypeCategory: ProjectTypeCategory.MOVE},
  });

  return (
    <CompanyDetailsPage
      selectedLabel={'modules/timesheets'}
      pageTitle={'Timesheets'}
      pageDescription={
        'Configure timesheets settings. Changes to the main branch will apply to the corporate and contractor branches.'
      }
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <TimesheetsSettingsContainer responsive={responsive}>
            <TimesheetsSettings
              responsive={responsive}
              primaryAndFranchiseOrganizations={data.company.primaryAndFranchiseOrganizations}
              refetch={refetch}
            />
          </TimesheetsSettingsContainer>
        )}
      </Loading>
    </CompanyDetailsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompanyModulesTimesheetsPage.query = gql`
  query CompanyModulesTimesheetsPage($slug: String!, $projectTypeCategory: String!) {
    ${gql.query}
    viewer {
      id
    }
    company(slug: $slug) {
      id
      primaryAndFranchiseOrganizations {
        id
        name
        kind
        projectTypes: projectTypesForCategory(category: $projectTypeCategory) {
          id
          name
          color
          features {
            timesheetsV2
          }
        }
      }
    }
  }
`;

export default CompanyModulesTimesheetsPage;
