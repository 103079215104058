const CUSTOMER = 'CUSTOMER';
const INTERNAL = 'INTERNAL';

const COMPANY_KIND_OPTIONS = [CUSTOMER, INTERNAL] as const;

export type CompanyKindType = (typeof COMPANY_KIND_OPTIONS)[number];

const getLabel = (kind: CompanyKindType) => {
  switch (kind) {
    case CUSTOMER:
      return 'Customer';
    case INTERNAL:
      return 'Internal';
    default:
      return kind;
  }
};

const getDropdownOptions = () =>
  COMPANY_KIND_OPTIONS.map((kind) => ({
    label: getLabel(kind),
    value: kind,
  }));

const CompanyKind = {
  INTERNAL,
  CUSTOMER,

  getLabel,
  getDropdownOptions,
};

export default CompanyKind;
