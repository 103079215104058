// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';

// App
import EmptyState from '@shared/design/components/EmptyState';

const Container = Styled.View`
`;

interface ManageDashboardCategoriesEmptyStateProps {
  handleCreate: () => void;
}

const ManageDashboardCategoriesEmptyState = ({
  handleCreate,
}: ManageDashboardCategoriesEmptyStateProps) => {
  return (
    <Container>
      <Space height={32} />
      <EmptyState
        title={'No categories.'}
        primaryActionIcon={Icon.Plus}
        primaryActionText={'Add Category'}
        handlePrimaryAction={handleCreate}
      />
      <Space height={32} />
    </Container>
  );
};

export default ManageDashboardCategoriesEmptyState;
