// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {CompanyModel} from '@supermove/models';

// App
import FieldValue from '@shared/design/components/Field/FieldValue';
import LocationCountry from '@shared/modules/Location/enums/LocationCountry';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const NONE = 'None';

const CompanyDetailsLocationPanel = ({company}: {company: CompanyModel}) => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <FieldValue
            label={'Country'}
            value={LocationCountry.getDisplay(company.locationCountry)}
            empty={NONE}
            isResponsive
          />
          <Space height={20} />
          <FieldValue label={'City'} value={company.locationCity} empty={NONE} isResponsive />
        </Column>
        <Space width={20} />
        <Column>
          <FieldValue label={'State'} value={company.locationState} empty={NONE} isResponsive />
        </Column>
      </Row>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompanyDetailsLocationPanel.fragment = gql`
  fragment CompanyDetailsLocationPanel on Company {
    id
    locationCity
    locationState
    locationCountry
  }
`;

export default CompanyDetailsLocationPanel;
