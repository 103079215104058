// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useMountEffect, useNavigation, useQuery, useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import GlobalHeader from 'modules/App/Navigation/GlobalHeader';
import GlobalSidebar from 'modules/App/Navigation/GlobalSidebar';
import UserAnalyticsTracker from 'modules/App/components/UserAnalyticsTracker';

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background}
`;

const Row = Styled.View`
  flex-direction: row;
  flex: 1;
`;

const AuthenticatedWrapperContent = ({viewer, children}: any) => {
  const {navigator} = useNavigation();
  const responsive = useResponsive();

  useMountEffect(() => {
    // If the viewer is null or the role isn't super, we redirect.
    if (_.get(viewer, 'role') !== 'super') {
      navigator.navigate('LoginPage');
    }
  });

  return (
    <React.Fragment>
      <UserAnalyticsTracker viewer={viewer} />
      <Container>
        {responsive.desktop ? (
          <GlobalHeader />
        ) : (
          <GlobalHeader.Mobile NavigationComponent={GlobalSidebar} />
        )}
        <Row>
          {responsive.desktop && <GlobalSidebar />}
          {children}
        </Row>
      </Container>
    </React.Fragment>
  );
};

const AuthenticatedWrapper = ({children}: any) => {
  const {loading, data} = useQuery(AuthenticatedWrapper.query, {
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return null;
  }

  return <AuthenticatedWrapperContent viewer={data.viewer} children={children} />;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AuthenticatedWrapper.query = gql`
  ${UserAnalyticsTracker.fragment}

  query AuthenticatedWrapper {
    ${gql.query}
    viewer {
      id
      role
      ...UserAnalyticsTracker
    }
  }
`;

export default AuthenticatedWrapper;
