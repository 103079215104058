// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CompanyForm, {CompanyFormProps} from '@shared/modules/Company/forms/CompanyForm';

const useCreateCompanyMutation = ({
  companyForm,
  onSuccess,
  onError,
}: {
  companyForm: CompanyFormProps;
  onSuccess: (response: any) => void;
  onError: (error: any) => void;
}) => {
  const form = useForm({
    initialValues: {
      companyForm: CompanyForm.toForm(companyForm),
    },
    enableReinitialize: true,
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateCompanyMutation.mutation,
    variables: {
      companyForm: CompanyForm.toMutation(form.values.companyForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateCompanyMutation.mutation = gql`
  mutation useCreateCompanyMutation($companyForm: CompanyForm!) {
    response: createCompany(companyForm: $companyForm) {
      ${gql.errors}
      company {
        id
      }
    }
  }
`;

export default useCreateCompanyMutation;
