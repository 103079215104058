// Libraries
import _ from 'lodash';
import React from 'react';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SupermoveProductKind from '@shared/modules/SupermoveProduct/enums/SupermoveProductKind';
import {SupermoveProductFormProps} from '@shared/modules/SupermoveProduct/forms/SupermoveProductForm';

const SalesProductFields = ({form, field: parentField}: {form: any; field: string}) => {
  const supermoveProductForms = _.get(form.values, `${parentField}.supermoveProductForms`);
  const index = _.findIndex(
    supermoveProductForms,
    (supermoveProductForm: SupermoveProductFormProps) =>
      supermoveProductForm.kind === SupermoveProductKind.SALES,
  );
  const field = `${parentField}.supermoveProductForms.${index}`;
  return (
    <FieldInput
      {...form}
      index={0}
      name={`${field}.numberOfLicenses`}
      label={'Number of Licenses'}
      isRequired
      isResponsive
      input={{
        placeholder: 'Enter number of licenses',
      }}
      handleBlur={(event: any) => {
        const text = event.target.value;
        form.setFieldValue(`${field}.numberOfLicenses`, _.parseInt(text) || '');
      }}
    />
  );
};

export default SalesProductFields;
