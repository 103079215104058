// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import ErrorCallout from '@shared/design/components/Callout/ErrorCallout';
import Switch from '@shared/design/components/Switch';
import CreateCompanyStepKinds from '@shared/modules/Company/enums/CreateCompanyStepKinds';
import SupermoveProductStatus from '@shared/modules/SupermoveProduct/enums/SupermoveProductStatus';
import {SupermoveProductFormProps} from '@shared/modules/SupermoveProduct/forms/SupermoveProductForm';

const Row = Styled.View`
  flex-direction: row;
`;

const ProductButton = Styled.ButtonV2`
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-vertical: 14px;
  padding-horizontal: 12px;
  background-color: ${({isSetupComplete}: {isSetupComplete?: boolean}) =>
    isSetupComplete ? colors.blue.accent : colors.white};
  border-color: ${({hasError, isSetupComplete}: {hasError?: boolean; isSetupComplete?: boolean}) =>
    hasError ? colors.red.warning : isSetupComplete ? colors.blue.interactive : colors.gray.border};
  border-width: 1px;
  border-radius: 4px;
`;

const ProductButtonText = Styled.Text<{vars: {isSetupComplete: boolean}}>`
  ${({vars}) => (vars.isSetupComplete ? Typography.Responsive.Label : Typography.Responsive.Body)}
  color: ${({vars}: {vars: {isSetupComplete: boolean}}) =>
    vars.isSetupComplete ? colors.blue.interactive : colors.gray.secondary};
`;

const ProductStatusText = Styled.Text<{vars: {isSetupComplete: boolean}}>`
  ${Typography.Responsive.Body}
  color: ${({vars}) => (vars.isSetupComplete ? colors.blue.interactive : colors.gray.secondary)};
`;

// Currently, all products are predefined products (SALES) are required, so the switch is disabled
const ProductSetupToggle = ({
  form,
  field,
  productStepKind,
  setCurrentStepKind,
  hasError,
}: {
  form: any;
  field: string;
  productStepKind: string;
  setCurrentStepKind: (kind: string) => void;
  hasError: Boolean;
}) => {
  const responsive = useResponsive();
  const isSetupComplete = _.get(form.values, `${field}.isSetupComplete`);
  const status = _.get(form.values, `${field}.status`);
  const isActive = status === SupermoveProductStatus.ACTIVE;
  return (
    <Row style={{alignItems: 'center'}}>
      <Switch
        isResponsive
        isOn={isActive}
        onChange={() => {
          form.setFieldValue(
            `${field}.status`,
            isActive ? SupermoveProductStatus.INACTIVE : SupermoveProductStatus.ACTIVE,
          );
        }}
        disabled
      />
      <Space width={12} />
      <ProductButton
        isSetupComplete={isSetupComplete}
        hasError={hasError}
        onPress={() => setCurrentStepKind(productStepKind)}
      >
        <Icon
          source={Icon.PhoneAlt}
          size={16}
          color={isSetupComplete ? colors.blue.interactive : colors.gray.secondary}
        />
        <Space width={8} />
        <ProductButtonText vars={{isSetupComplete}} responsive={responsive}>
          {CreateCompanyStepKinds.getStepTitle(productStepKind)}
        </ProductButtonText>
        <Space style={{flex: 1, minWidth: 8}} />
        <ProductStatusText vars={{isSetupComplete}} responsive={responsive}>
          {isSetupComplete ? 'Setup Complete' : 'Click to Set Up'}
        </ProductStatusText>
        <Space width={8} />
        <Icon
          source={Icon.AngleRight}
          size={12}
          color={isSetupComplete ? colors.blue.interactive : colors.gray.secondary}
        />
      </ProductButton>
    </Row>
  );
};

const ProductsOverviewFields = ({
  form,
  field,
  setCurrentStepKind,
}: {
  form: any;
  field: string;
  setCurrentStepKind: (kind: string) => void;
}) => {
  const supermoveProductsError = _.get(form.errors, 'companyForm.supermoveProductForms');
  const supermoveProductForms = _.get(form.values, `${field}.supermoveProductForms`);
  return (
    <React.Fragment>
      {supermoveProductsError && (
        <React.Fragment>
          <ErrorCallout text={supermoveProductsError} />
          <Space height={16} />
        </React.Fragment>
      )}
      {supermoveProductForms.map(
        (supermoveProductForm: SupermoveProductFormProps, index: number) => {
          const hasError =
            supermoveProductsError &&
            supermoveProductForm.status === SupermoveProductStatus.ACTIVE &&
            !supermoveProductForm.isSetupComplete;
          return (
            <React.Fragment>
              <ProductSetupToggle
                form={form}
                field={`${field}.supermoveProductForms.${index}`}
                productStepKind={CreateCompanyStepKinds.getStepKindFromSupermoveProductKind(
                  supermoveProductForm.kind,
                )}
                setCurrentStepKind={setCurrentStepKind}
                hasError={hasError}
              />
              <Space height={16} />
            </React.Fragment>
          );
        },
      )}
    </React.Fragment>
  );
};

export default ProductsOverviewFields;
