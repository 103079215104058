// Libraries
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useToast} from '@supermove/hooks';
import {SupermoveProductModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import Badge from '@shared/design/components/Badge';
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import DateFieldComponent from '@shared/design/components/Field/DateField';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import SupermoveProductKind from '@shared/modules/SupermoveProduct/enums/SupermoveProductKind';
import SupermoveProductStatus from '@shared/modules/SupermoveProduct/enums/SupermoveProductStatus';
import SupermoveProductSettingsForm from '@shared/modules/SupermoveProduct/forms/SupermoveProductSettingsForm';
import useSupermoveProductSettingsMutation from '@shared/modules/SupermoveProduct/hooks/useUpdateSupermoveProductSettingsMutation';

const Container = Styled.View`
`;

interface UpdateSupermoveProductSettingsDrawerProps {
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
  supermoveProduct: SupermoveProductModel;
}

const UpdateSupermoveProductSettingsDrawer = ({
  isOpen,
  handleClose,
  refetch,
  supermoveProduct,
}: UpdateSupermoveProductSettingsDrawerProps) => {
  const responsive = useResponsive();
  const successToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Changes saved.',
  });
  const supermoveProductSettingsForm = SupermoveProductSettingsForm.edit(supermoveProduct);
  const {form, submitting, handleSubmit} = useSupermoveProductSettingsMutation({
    supermoveProductSettingsForm,
    onSuccess: () => {
      successToast.handleToast();
      refetch();
      handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={SupermoveProductKind.getLabel(supermoveProduct.kind)}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
    >
      <Container responsive={responsive}>
        <Container responsive={responsive}>
          <FieldInput.LabelText isResponsive>Status</FieldInput.LabelText>
          <FieldInput.Spacer isResponsive />
          <Badge
            label={SupermoveProductStatus.getDisplay(supermoveProduct.status)}
            color={
              supermoveProduct.status === SupermoveProductStatus.ACTIVE
                ? colors.green.status
                : colors.gray.secondary
            }
            isResponsive
            style={{width: 'fit-content'}}
          />
        </Container>
        <Space height={16} />
        <DateFieldComponent
          form={form}
          label={'Deployed Date'}
          field={'supermoveProductSettingsForm.deployedDate'}
          isFullWidth
          isClearable
        />
      </Container>
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateSupermoveProductSettingsDrawer.fragment = gql`
  fragment UpdateSupermoveProductSettingsDrawer on SupermoveProduct {
    id
    kind
    status
    deployedDate
  }
`;

export default UpdateSupermoveProductSettingsDrawer;
