// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useCallback} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import ValidationError from './ValidationError';

const Container = Styled.View`
  z-index: ${(props) => 100 - (props as any).index};
`;

const Label = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.secondary};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const Input = Styled.TextInput.H7`
  margin-top: 3px;
`;

const Field = ({
  index = 0,
  name,
  label,
  touched,
  errors,
  values,
  component: InputComponent = Input,
  input = {},
  setFieldValue,
  setFieldTouched,
  style,
}: any) => {
  const onChangeText = useCallback((text) => setFieldValue(name, text), [name, setFieldValue]);
  const onBlur = useCallback(() => setFieldTouched(name, true), [name, setFieldTouched]);

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Container index={index} style={style}>
      <Label>{label}</Label>
      <InputComponent
        {...input}
        name={name}
        value={_.get(values, name)}
        onChangeText={onChangeText}
        onBlur={onBlur}
      />
      <ValidationError name={name} touched={touched} errors={errors} />
    </Container>
  );
};

export default Field;
