// Libraries
import _ from 'lodash';

// App
import SupermoveProductKind from '@shared/modules/SupermoveProduct/enums/SupermoveProductKind';
import {SupermoveProductFormProps} from '@shared/modules/SupermoveProduct/forms/SupermoveProductForm';

const COMPANY_DETAILS = 'COMPANY_DETAILS';
const REVIEW = 'REVIEW';

const PRODUCTS_OVERVIEW = 'PRODUCTS_OVERVIEW';
const SALES_PRODUCT = 'SALES_PRODUCT';
const PRODUCTS = {
  OVERVIEW: PRODUCTS_OVERVIEW,
  SALES: SALES_PRODUCT,
};

const STEP_TITLES: Record<string, string> = {
  [COMPANY_DETAILS]: 'Company Details',
  [PRODUCTS.OVERVIEW]: 'Products',
  [PRODUCTS.SALES]: 'Sales Copilot',
  [REVIEW]: 'Review',
};
const getStepTitle = (stepKind: string) => STEP_TITLES[stepKind] || '';

const STEP_DESCRIPTIONS: Record<string, string> = {
  [COMPANY_DETAILS]:
    'Enter the company name and identifier, which will be used for logging in. This can be updated later.',
  [PRODUCTS.OVERVIEW]: 'Select and set up the products this company will use.',
  [PRODUCTS.SALES]: 'Enter the number of people that will be using Sales Copilot.',
  [REVIEW]: 'Before submitting, make sure this information is correct.',
};
const getStepDescription = (stepKind: string) => STEP_DESCRIPTIONS[stepKind] || '';

const getPreviousStepKind = (stepKind: string) => {
  switch (stepKind) {
    case PRODUCTS.OVERVIEW:
      return COMPANY_DETAILS;
    case PRODUCTS.SALES:
    case REVIEW:
      return PRODUCTS.OVERVIEW;
    default:
      return null;
  }
};

const getNextStepKind = (stepKind: string) => {
  switch (stepKind) {
    case COMPANY_DETAILS:
      return PRODUCTS.OVERVIEW;
    case PRODUCTS.OVERVIEW:
      return REVIEW;
    case PRODUCTS.SALES:
      return PRODUCTS.OVERVIEW;
    default:
      return null;
  }
};

const getNextStepButtonText = (stepKind: string) => {
  switch (stepKind) {
    case PRODUCTS.OVERVIEW:
      return 'All Products';
    default:
      return getStepTitle(stepKind);
  }
};

const getStepKindFromSupermoveProductKind = (kind: string) => {
  switch (kind) {
    case SupermoveProductKind.SALES:
      return PRODUCTS.SALES;
    default:
      return '';
  }
};

const completeProductSetup = ({stepKind, form}: {stepKind: string; form: any}) => {
  switch (stepKind) {
    case PRODUCTS.SALES: {
      const supermoveProductForms = _.get(form.values, 'companyForm.supermoveProductForms');
      const index = _.findIndex(
        supermoveProductForms,
        (supermoveProductForm: SupermoveProductFormProps) =>
          supermoveProductForm.kind === SupermoveProductKind.SALES,
      );
      form.setFieldValue(`companyForm.supermoveProductForms.${index}.isSetupComplete`, true);
      break;
    }
    default:
      break;
  }
};

const CreateCompanyStepKinds = {
  COMPANY_DETAILS,
  PRODUCTS,
  REVIEW,

  // Helpers
  getStepTitle,
  getStepDescription,
  getPreviousStepKind,
  getNextStepKind,
  getNextStepButtonText,
  getStepKindFromSupermoveProductKind,
  completeProductSetup,
};

export default CreateCompanyStepKinds;
