// Libraries
import React from 'react';

// Supermove
import {Loading, Space} from '@supermove/components';
import {generated, gqlTyped as gql, useTypedQuery as useQuery} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';
import {existenceFilter} from '@supermove/utils';

// App
import Callout from '@shared/design/components/Callout';
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import GlobalDashboardForm from '@shared/modules/GlobalDashboard/forms/GlobalDashboardForm';
import useUpdateGlobalDashboardMutation from '@shared/modules/GlobalDashboard/hooks/useUpdateGlobalDashboardMutation';
import GlobalDashboardFields from 'modules/Dashboards/components/GlobalDashboardFields';

const UpdateGlobalDashboardDrawerContent = ({
  isOpen,
  isLoading,
  globalDashboard,
  dashboardCategories,
  dashboardTags,
  handleClose,
  refetch,
}: {
  isOpen: boolean;
  isLoading: boolean;
  globalDashboard: generated.GlobalDashboardFormEditFragment;
  dashboardCategories: NonNullable<generated.GlobalDashboardFieldsFragment['dashboardCategories']>;
  dashboardTags: NonNullable<generated.GlobalDashboardFieldsFragment['dashboardTags']>;
  handleClose: () => void;
  refetch: () => void;
}) => {
  const updateGlobalDashboardSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Dashboard updated!',
  });

  const globalDashboardForm = GlobalDashboardForm.edit(globalDashboard);
  const {form, submitting, handleSubmit} = useUpdateGlobalDashboardMutation({
    globalDashboardForm,
    onSuccess: ({globalDashboard}) => {
      updateGlobalDashboardSuccessToast.handleToast({
        message: `'${globalDashboard.name}' updated!`,
      });
      refetch();
      handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      shouldCloseOnClickOutside={!form.dirty}
      headerText={'Edit Global Report'}
      primaryActionText={'Save'}
      handleSubmit={handleSubmit}
      isSubmitting={submitting}
      isDisabled={isLoading}
    >
      <Loading loading={isLoading}>
        {() => (
          <React.Fragment>
            <Callout text='You are editing a Global Report. Changes made will affect any company who has this report.' />
            <Space height={16} />
            <GlobalDashboardFields
              form={form}
              dashboardCategories={dashboardCategories}
              dashboardTags={dashboardTags}
            />
          </React.Fragment>
        )}
      </Loading>
    </DrawerWithAction>
  );
};

const UpdateGlobalDashboardDrawer = ({
  isOpen,
  globalDashboardUuid,
  handleClose,
  refetch,
}: {
  isOpen: boolean;
  globalDashboardUuid: string;
  handleClose: () => void;
  refetch: () => void;
}) => {
  const {loading, data} = useQuery(UpdateGlobalDashboardDrawerQuery, {
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
    variables: {
      globalDashboardUuid,
    },
  });

  if (!data || !data.globalDashboard) {
    return null;
  }

  return (
    <UpdateGlobalDashboardDrawerContent
      isOpen={isOpen}
      isLoading={loading}
      globalDashboard={data.globalDashboard}
      dashboardCategories={data.dashboardCategories?.filter(existenceFilter) || []}
      dashboardTags={data.dashboardTags?.filter(existenceFilter) || []}
      handleClose={handleClose}
      refetch={refetch}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
const UpdateGlobalDashboardDrawerQuery = gql(`
  query UpdateGlobalDashboardDrawer($globalDashboardUuid: String!) {
    globalDashboard(uuid: $globalDashboardUuid) {
      id
      ...GlobalDashboardFormEdit
    }
    ...GlobalDashboardFields
  }
`);

export default UpdateGlobalDashboardDrawer;
