// Supermove
import {generated} from '@supermove/graphql';

// App
import DashboardTagForm from '@shared/modules/DashboardTag/forms/DashboardTagForm';

const edit = (reportDashboards: generated.DashboardTagFormEditFragment[]) => ({
  dashboardTagForms: reportDashboards.map((reportDashboard) => {
    return DashboardTagForm.edit(reportDashboard);
  }),
});

const toForm = ({dashboardTagForms}: ReturnType<typeof edit>) => ({
  dashboardTagForms: dashboardTagForms.map((reportDashboardForm) => {
    return DashboardTagForm.toForm(reportDashboardForm);
  }),
});

const toMutation = ({dashboardTagForms}: ReturnType<typeof toForm>) => ({
  dashboardTagForms: dashboardTagForms.map((reportDashboardForm) => {
    return DashboardTagForm.toMutation(reportDashboardForm);
  }),
});

const DashboardTagsForm = {
  edit,
  toForm,
  toMutation,
};

export default DashboardTagsForm;
