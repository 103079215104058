// Libraries
import React from 'react';

// Supermove
import {useContext} from '@supermove/hooks';

type EditableSortableListContextProps = {
  indexOfEdit: number | null;
  setIndexOfEdit: (index: number | null) => void;
};

const EditableSortableListContext = React.createContext<EditableSortableListContextProps>({
  indexOfEdit: null,
  setIndexOfEdit: () => {},
});

export const useEditableSortableListContext = () => {
  return useContext(EditableSortableListContext);
};

export default EditableSortableListContext;
