// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';

const Container = Styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 100;
`;

const BodyText = Styled.Text`
  ${Typography.Responsive.Body}
`;

interface ManageGlobalDashboardCompaniesDrawerListBulkActionsProps {
  selectedOrganizationIdsCount: number;
  handleAddReportToSelectedOrganizations: () => void;
  handleRemoveReportFromSelectedOrganizations: () => void;
}

const ManageGlobalDashboardCompaniesDrawerListBulkActions = ({
  selectedOrganizationIdsCount,
  handleAddReportToSelectedOrganizations,
  handleRemoveReportFromSelectedOrganizations,
}: ManageGlobalDashboardCompaniesDrawerListBulkActionsProps) => {
  const countTextStyle = {
    color: selectedOrganizationIdsCount > 0 ? colors.blue.interactive : colors.gray.secondary,
    fontWeight: selectedOrganizationIdsCount > 0 ? 'bold' : undefined,
  };

  return (
    <Container>
      <BodyText style={countTextStyle}>{selectedOrganizationIdsCount} companies selected</BodyText>
      <Space width={16} />
      <DropdownButton
        text='Actions'
        ButtonComponent={SecondaryButton}
        isSmall
        style={{width: 88}}
        menuWidth={300}
        actions={[
          {
            text: 'Add report to selected companies',
            onPress: handleAddReportToSelectedOrganizations,
          },
          {
            text: 'Remove report from selected companies',
            onPress: handleRemoveReportFromSelectedOrganizations,
          },
        ]}
        isWidthOfContainer
        isResponsive
      />
    </Container>
  );
};

export default ManageGlobalDashboardCompaniesDrawerListBulkActions;
