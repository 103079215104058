// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import Badge from '@shared/design/components/Badge';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import DashboardStatus, {
  DashboardStatusType,
} from '@shared/modules/GlobalDashboard/enums/DashboardStatus';
import {GlobalDashboardSortKind} from '@shared/modules/GlobalDashboard/enums/GlobalDashboardSortKind';
import GlobalDashboardFilter from 'modules/Dashboards/components/GlobalDashboardFilter';
import {GlobalDashboardsPageUrlFilters} from 'modules/Dashboards/components/types';

const Container = Styled.View`
  z-index: 100;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ListHeader = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const ListHeaderDescription = Styled.Text`
  ${Typography.Responsive.Body}
`;

const ListHeaderDefaultDescription = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const ButtonContainer = Styled.View`
  ${({responsive}: {responsive: ResponsiveType}) => (responsive.desktop ? '' : 'flex: 1')};
`;

const sortKindToButtonText = (sortKind: GlobalDashboardSortKind) => {
  switch (sortKind) {
    case GlobalDashboardSortKind.ALPHABETICAL:
      return 'Sort: A-Z';
    case GlobalDashboardSortKind.NEWEST:
      return 'Sort: Newest';
    case GlobalDashboardSortKind.MOST_POPULAR:
      return 'Sort: Popularity';
  }
};

interface ResponsiveType {
  desktop: boolean;
  tablet: boolean;
  mobile: boolean;
}

const GlobalDashboardListHeader = ({
  dashboardStatus,
  urlFilters,
}: {
  dashboardStatus: DashboardStatusType;
  urlFilters: GlobalDashboardsPageUrlFilters;
}) => {
  const responsive = useResponsive();

  const defaultDescription =
    dashboardStatus === 'PUBLISHED'
      ? 'This is the default dashboard that is automatically added to all new companies.'
      : 'Indicates that the report is automatically added to new companies when published.';

  return (
    <React.Fragment>
      <Container>
        <Space height={24} />
        <ListHeader responsive={responsive}>{DashboardStatus.getLabel(dashboardStatus)}</ListHeader>
        <Space height={12} />
        <ListHeaderDescription responsive={responsive}>
          {DashboardStatus.getDescription(dashboardStatus)}
        </ListHeaderDescription>
        {dashboardStatus !== 'ARCHIVED' && (
          <React.Fragment>
            <Space height={12} />
            <Row>
              <Container>
                <Badge label={'Default'} />
              </Container>
              <Space width={8} />
              <ListHeaderDefaultDescription responsive={responsive}>
                {defaultDescription}
              </ListHeaderDefaultDescription>
            </Row>
          </React.Fragment>
        )}
        <Space height={16} />
        <Row>
          <ButtonContainer responsive={responsive}>
            <GlobalDashboardFilter urlFilters={urlFilters} handleApply={urlFilters.handleUpdate} />
          </ButtonContainer>
          <Space width={16} />
          <ButtonContainer responsive={responsive}>
            <DropdownButton
              text={sortKindToButtonText(urlFilters.get('sort'))}
              ButtonComponent={SecondaryButton}
              isSmall
              menuWidth={160}
              actions={[
                {
                  text: 'Sort A-Z',
                  onPress: () =>
                    urlFilters.handleUpdate({sort: GlobalDashboardSortKind.ALPHABETICAL}),
                },
                {
                  text: 'Sort by newest',
                  onPress: () => urlFilters.handleUpdate({sort: GlobalDashboardSortKind.NEWEST}),
                },
                {
                  text: 'Sort by popularity',
                  onPress: () =>
                    urlFilters.handleUpdate({sort: GlobalDashboardSortKind.MOST_POPULAR}),
                },
              ]}
              isWidthOfContainer
              isResponsive
            />
          </ButtonContainer>
        </Row>
        <Space height={16} />
      </Container>
    </React.Fragment>
  );
};

export default GlobalDashboardListHeader;
