// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useMemo, useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import SearchBar from '@shared/design/components/SearchBar';
import ManageGlobalDashboardCompaniesDrawerListBulkActions from 'modules/Dashboards/DashboardDetails/components/ManageGlobalDashboardCompaniesDrawerListBulkActions';
import ManageGlobalDashboardCompaniesDrawerListEmptyState from 'modules/Dashboards/DashboardDetails/components/ManageGlobalDashboardCompaniesDrawerListEmptyState';
import ManageGlobalDashboardCompaniesDrawerListGroupHeaderItem from 'modules/Dashboards/DashboardDetails/components/ManageGlobalDashboardCompaniesDrawerListGroupHeaderItem';
import ManageGlobalDashboardCompaniesDrawerListHeader from 'modules/Dashboards/DashboardDetails/components/ManageGlobalDashboardCompaniesDrawerListHeader';
import ManageGlobalDashboardCompaniesDrawerListItem from 'modules/Dashboards/DashboardDetails/components/ManageGlobalDashboardCompaniesDrawerListItem';

const Container = Styled.View`
  display: block;
`;

const ListContainer = Styled.View`
  display: block;
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  overflow: hidden;
`;

const ItemContainer = Styled.View`
  border-top-width: 1px;
  border-color: ${colors.gray.border};
  flex: 1;
`;

export interface OrganizationData {
  id: string;
  name: string;
  company: {
    id: number;
    primaryOrganization: {
      id: number;
      name: string;
    };
  };
}

const getPrimaryOrganizationToBranchOrganizationsMap = ({
  activeOrganizations,
}: {
  activeOrganizations: OrganizationData[];
}) => {
  return activeOrganizations
    .sort((a, b) => a.name.localeCompare(b.name))
    .reduce(
      (result, organization) => {
        const {
          company: {
            primaryOrganization: {id: primaryOrganizationId},
          },
        } = organization;
        if (primaryOrganizationId) {
          const branches = result[primaryOrganizationId] || [];
          result[primaryOrganizationId] = [...branches, organization];
        } else {
          result[_.toNumber(organization.id)] = [organization];
        }
        return result;
      },
      {} as Record<number, OrganizationData[]>,
    );
};

const isGroupSelected = ({
  primaryOrganizationToChildOrganizationsMap,
  selectedOrganizationIds,
  organizationId,
}: {
  primaryOrganizationToChildOrganizationsMap: Record<number, OrganizationData[]>;
  selectedOrganizationIds: Set<number>;
  organizationId: number;
}) => {
  const childOrganizationIds =
    primaryOrganizationToChildOrganizationsMap[organizationId]?.map((o) => o.id) || [];
  return childOrganizationIds.every((id) => selectedOrganizationIds.has(_.toNumber(id)));
};

const handleOpenGroup = ({
  setClosedOrganizationIds,
  organizationId,
}: {
  setClosedOrganizationIds: React.Dispatch<React.SetStateAction<Set<number>>>;
  organizationId: number;
}) => {
  setClosedOrganizationIds((prev) => {
    if (prev.has(organizationId)) {
      return new Set([...prev].filter((id) => id !== organizationId));
    }
    return new Set([...prev, organizationId]);
  });
};

const handleSelectGroup = ({
  setSelectedOrganizationIds,
  primaryOrganizationToChildOrganizationsMap,
  organizationId,
  isSelected,
}: {
  setSelectedOrganizationIds: React.Dispatch<React.SetStateAction<Set<number>>>;
  primaryOrganizationToChildOrganizationsMap: Record<number, OrganizationData[]>;
  organizationId: number;
  isSelected: boolean;
}) => {
  setSelectedOrganizationIds((prev) => {
    const childOrganizationIds = primaryOrganizationToChildOrganizationsMap[organizationId].map(
      (o) => _.toNumber(o.id),
    );
    if (!isSelected) {
      return new Set([...prev].filter((id) => !childOrganizationIds.includes(id)));
    }
    return new Set([...prev, ...childOrganizationIds]);
  });
};

const handleSelectOrganization = ({
  setSelectedOrganizationIds,
  organizationId,
}: {
  setSelectedOrganizationIds: React.Dispatch<React.SetStateAction<Set<number>>>;
  organizationId: number;
}) => {
  setSelectedOrganizationIds((prev) => {
    if (prev.has(organizationId)) {
      return new Set([...prev].filter((id) => id !== organizationId));
    }
    return new Set([...prev, organizationId]);
  });
};

const isAllSelected = ({
  activeOrganizations,
  selectedOrganizationIds,
}: {
  activeOrganizations: OrganizationData[];
  selectedOrganizationIds: Set<number>;
}) => {
  return activeOrganizations.every((o) => selectedOrganizationIds.has(_.toNumber(o.id)));
};

const handleSelectAll = ({
  isSelected,
  setSelectedOrganizationIds,
  activeOrganizations,
}: {
  isSelected: boolean;
  setSelectedOrganizationIds: React.Dispatch<React.SetStateAction<Set<number>>>;
  activeOrganizations: OrganizationData[];
}) => {
  if (isSelected) {
    setSelectedOrganizationIds(new Set(activeOrganizations.map((o) => _.toNumber(o.id))));
  } else {
    setSelectedOrganizationIds(new Set());
  }
};

interface ManageGlobalDashboardCompaniesDrawerListProps {
  globalDashboardId: string;
  activeOrganizations: OrganizationData[];
  enabledOrganizationIds: Set<number>;
  handleToggle: ({
    organizationIds,
    isEnable,
  }: {
    organizationIds: number[];
    isEnable: boolean;
  }) => void;
}

const ManageGlobalDashboardCompaniesDrawerList = ({
  globalDashboardId,
  activeOrganizations,
  enabledOrganizationIds,
  handleToggle,
}: ManageGlobalDashboardCompaniesDrawerListProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const handleSetSearchQuery = _.debounce((query: string) => setSearchQuery(query), 300);

  const [selectedOrganizationIds, setSelectedOrganizationIds] = useState<Set<number>>(new Set());
  const [closedOrganizationIds, setClosedOrganizationIds] = useState<Set<number>>(new Set());

  const filteredActiveOrganizations = useMemo(
    () =>
      searchQuery
        ? activeOrganizations.filter((organization) =>
            organization.name.toLowerCase().includes(searchQuery.toLowerCase()),
          )
        : activeOrganizations,
    [activeOrganizations, searchQuery],
  );

  const primaryOrganizations = useMemo(() => {
    const uniquePrimaryOrganizationIds = new Set<number>();
    return activeOrganizations
      .reduce((result, organization) => {
        if (
          organization.company?.primaryOrganization &&
          !uniquePrimaryOrganizationIds.has(organization.company.primaryOrganization.id)
        ) {
          uniquePrimaryOrganizationIds.add(organization.company.primaryOrganization.id);
          result.push(organization.company.primaryOrganization);
        }
        return result;
      }, new Array<OrganizationData['company']['primaryOrganization']>())
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [activeOrganizations]);

  const filteredPrimaryOrganizationToChildOrganizationsMap = useMemo(
    () =>
      getPrimaryOrganizationToBranchOrganizationsMap({
        activeOrganizations: filteredActiveOrganizations,
      }),
    [filteredActiveOrganizations],
  );

  const primaryOrganizationToChildOrganizationsMap = useMemo(
    () =>
      getPrimaryOrganizationToBranchOrganizationsMap({
        activeOrganizations,
      }),
    [activeOrganizations],
  );

  return (
    <Container>
      <SearchBar
        placeholder='Search by company name'
        style={{width: '100%'}}
        onChangeText={handleSetSearchQuery}
        isClearable
      />
      <Space height={16} />
      <ManageGlobalDashboardCompaniesDrawerListBulkActions
        selectedOrganizationIdsCount={selectedOrganizationIds.size}
        handleAddReportToSelectedOrganizations={() =>
          handleToggle({
            organizationIds: Array.from(selectedOrganizationIds),
            isEnable: true,
          })
        }
        handleRemoveReportFromSelectedOrganizations={() =>
          handleToggle({
            organizationIds: Array.from(selectedOrganizationIds),
            isEnable: false,
          })
        }
      />
      <Space height={16} />
      <ListContainer>
        <ManageGlobalDashboardCompaniesDrawerListHeader
          isSelected={isAllSelected({
            activeOrganizations,
            selectedOrganizationIds,
          })}
          handleSelect={(isSelected) =>
            handleSelectAll({
              isSelected,
              setSelectedOrganizationIds,
              activeOrganizations,
            })
          }
        />
        {activeOrganizations.length > 0 ? (
          primaryOrganizations
            .filter(
              (o) =>
                filteredPrimaryOrganizationToChildOrganizationsMap[_.toNumber(o.id)]?.length > 0,
            )
            .map((organization, idx) => (
              <React.Fragment key={organization.id}>
                {primaryOrganizationToChildOrganizationsMap[_.toNumber(organization.id)]?.length >
                1 ? (
                  <React.Fragment>
                    <ItemContainer>
                      <ManageGlobalDashboardCompaniesDrawerListGroupHeaderItem
                        title={organization.name}
                        isSelected={isGroupSelected({
                          primaryOrganizationToChildOrganizationsMap,
                          selectedOrganizationIds,
                          organizationId: _.toNumber(organization.id),
                        })}
                        isOpen={!closedOrganizationIds.has(_.toNumber(organization.id))}
                        handleOpen={() =>
                          handleOpenGroup({
                            setClosedOrganizationIds,
                            organizationId: _.toNumber(organization.id),
                          })
                        }
                        handleSelect={(isSelected) =>
                          handleSelectGroup({
                            setSelectedOrganizationIds,
                            primaryOrganizationToChildOrganizationsMap,
                            organizationId: _.toNumber(organization.id),
                            isSelected,
                          })
                        }
                      />
                    </ItemContainer>
                    {!closedOrganizationIds.has(_.toNumber(organization.id)) &&
                      filteredPrimaryOrganizationToChildOrganizationsMap[
                        _.toNumber(organization.id)
                      ]?.map((organization) => (
                        <ItemContainer
                          key={organization.id}
                          style={{
                            borderBottomWidth: idx === primaryOrganizations.length - 1 ? 1 : 0,
                          }}
                        >
                          <ManageGlobalDashboardCompaniesDrawerListItem
                            organizationName={organization.name}
                            isPrimary={false}
                            isEnabled={enabledOrganizationIds.has(_.toNumber(organization.id))}
                            handleToggle={() =>
                              handleToggle({
                                organizationIds: [_.toNumber(organization.id)],
                                isEnable: !enabledOrganizationIds.has(_.toNumber(organization.id)),
                              })
                            }
                            isSelected={selectedOrganizationIds.has(_.toNumber(organization.id))}
                            handleSelect={() =>
                              handleSelectOrganization({
                                setSelectedOrganizationIds,
                                organizationId: _.toNumber(organization.id),
                              })
                            }
                          />
                        </ItemContainer>
                      ))}
                  </React.Fragment>
                ) : (
                  <ItemContainer>
                    <ManageGlobalDashboardCompaniesDrawerListItem
                      organizationName={organization.name}
                      isPrimary
                      isEnabled={enabledOrganizationIds.has(_.toNumber(organization.id))}
                      handleToggle={() => {
                        handleToggle({
                          organizationIds: [_.toNumber(organization.id)],
                          isEnable: !enabledOrganizationIds.has(_.toNumber(organization.id)),
                        });
                      }}
                      isSelected={selectedOrganizationIds.has(_.toNumber(organization.id))}
                      handleSelect={() =>
                        handleSelectOrganization({
                          setSelectedOrganizationIds,
                          organizationId: _.toNumber(organization.id),
                        })
                      }
                    />
                  </ItemContainer>
                )}
              </React.Fragment>
            ))
        ) : (
          <ManageGlobalDashboardCompaniesDrawerListEmptyState />
        )}
      </ListContainer>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

ManageGlobalDashboardCompaniesDrawerList.fragment = gql`
  fragment ManageGlobalDashboardCompaniesDrawerListFragment on Organization {
    id
    name
    company {
      id
      primaryOrganization {
        id
        name
      }
    }
  }
`;

export default ManageGlobalDashboardCompaniesDrawerList;
