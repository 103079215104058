// Supermove
import {gql} from '@supermove/graphql';
import {CompanyModel} from '@supermove/models';
import {withFragment, Datetime} from '@supermove/utils';

export interface CompanyDetailsHistoryFormType {
  companyId: string;
  createdAt: string;
  contractSignedDate: string;
}

const edit = withFragment(
  (company: CompanyModel) => ({
    companyId: company.id,
    createdAt: company.createdAt,
    contractSignedDate: company.contractSignedDate,
  }),
  gql`
    fragment CompanyDetailsHistoryForm_edit on Company {
      id
      createdAt
      contractSignedDate
    }
  `,
);

const toForm = ({companyId, createdAt, contractSignedDate}: CompanyDetailsHistoryFormType) => ({
  companyId,
  createdAt: Datetime.toFormDate(createdAt),
  contractSignedDate: Datetime.toFormDate(contractSignedDate),
});

// createdAt is a disabled, non-editable value, so it is excluded from toMutation
const toMutation = ({companyId, contractSignedDate}: CompanyDetailsHistoryFormType) => ({
  companyId,
  contractSignedDate: contractSignedDate ? Datetime.toMutationDate(contractSignedDate) : null,
});

const CompanyDetailsHistoryForm = {
  edit,
  toForm,
  toMutation,
};

export default CompanyDetailsHistoryForm;
