// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useMountEffect, useQuery} from '@supermove/hooks';

// App
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';

// ////////////////////////////////////////////////////////////////////
// FEATURE FLAG REMOVAL: ADMIN_MANAGE_COMPANY
// ////////////////////////////////////////////////////////////////////
const RedirectToCompanyDetailsProductsPage = () => {
  const {navigator, params} = useNavigation();

  useMountEffect(() => {
    navigator.replace('CompanyDetailsProductsPage', {slug: params.slug});
  });

  return null;
};
// ////////////////////////////////////////////////////////////////////
// FEATURE FLAG REMOVAL BLOCK END
// ////////////////////////////////////////////////////////////////////

// TODO(Hammad) navigator.replace does not seem to solve an issue with back-button behavior.
const RedirectToCompanyDetailsOverviewPage = () => {
  const {navigator, params} = useNavigation();

  useMountEffect(() => {
    navigator.replace('CompanyDetailsOverviewPage', {slug: params.slug});
  });

  return null;
};

const CompanyRootPage = () => {
  const {loading, data} = useQuery(CompanyRootPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {},
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() =>
        // FEATURE FLAG REMOVAL: ADMIN_MANAGE_COMPANY
        data.viewer.viewingOrganization.features.isEnabledAdminManageCompany ? (
          <RedirectToCompanyDetailsOverviewPage />
        ) : (
          <RedirectToCompanyDetailsProductsPage />
        )
      }
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompanyRootPage.query = gql`
  query CompanyDetailsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        slug
        features {
          isEnabledAdminManageCompany: isEnabled(feature: "ADMIN_MANAGE_COMPANY")
        }
      }
    }
  }
`;

export default CompanyRootPage;
