// App
import {DashboardStatusType} from '@shared/modules/GlobalDashboard/enums/DashboardStatus';

export interface UpdateGlobalDashboardStatusFormProps {
  globalDashboardId: string;
  status: DashboardStatusType;
}

const toForm = (updateGlobalDashboardStatusForm: UpdateGlobalDashboardStatusFormProps) => ({
  globalDashboardId: updateGlobalDashboardStatusForm.globalDashboardId,
  status: updateGlobalDashboardStatusForm.status,
});

const toMutation = (updateGlobalDashboardStatusForm: UpdateGlobalDashboardStatusFormProps) => ({
  globalDashboardId: updateGlobalDashboardStatusForm.globalDashboardId,
  status: updateGlobalDashboardStatusForm.status,
});

const UpdateGlobalDashboardStatusForm = {
  toForm,
  toMutation,
};

export default UpdateGlobalDashboardStatusForm;
