// Libraries
import _ from 'lodash';

// Supermove
import {Validation} from '@supermove/utils';
import {ValidationErrors} from '@supermove/utils/src/Validation';

// App
import CreateCompanyStepKinds from '@shared/modules/Company/enums/CreateCompanyStepKinds';
import SupermoveProductKind from '@shared/modules/SupermoveProduct/enums/SupermoveProductKind';
import SupermoveProductStatus from '@shared/modules/SupermoveProduct/enums/SupermoveProductStatus';
import SupermoveProductForm, {
  SupermoveProductFormProps,
} from '@shared/modules/SupermoveProduct/forms/SupermoveProductForm';

export interface CompanyFormProps {
  companyId: number | null;
  name: string;
  identifier: string;
  supermoveProductForms: SupermoveProductFormProps[];
}

const _new = ({supermoveProductForms}: {supermoveProductForms?: SupermoveProductFormProps[]}) => ({
  companyId: null,
  name: '',
  identifier: '',
  supermoveProductForms: supermoveProductForms || [],
});

const toForm = (companyForm: CompanyFormProps) => ({
  companyId: companyForm.companyId,
  name: companyForm.name,
  identifier: companyForm.identifier,
  supermoveProductForms: companyForm.supermoveProductForms.map(
    (supermoveProductForm: SupermoveProductFormProps) =>
      SupermoveProductForm.toForm(supermoveProductForm),
  ),
});

const toMutation = (companyForm: CompanyFormProps) => ({
  companyId: companyForm.companyId,
  name: companyForm.name,
  identifier: companyForm.identifier,
  supermoveProductForms: companyForm.supermoveProductForms.map(
    (supermoveProductForm: SupermoveProductFormProps) =>
      SupermoveProductForm.toMutation(supermoveProductForm),
  ),
});

// Valid identifiers must be at least 3 characters long and only contain letters, numbers, -, and _
const validateIdentifier = ({form, field}: {form: any; field: string}) => {
  const identifier = _.get(form.values, field);
  const isValidIdentifier =
    identifier.length >= 3 && /^[a-zA-Z0-9-_]+$/.test(identifier) && !identifier.includes(' ');
  if (!isValidIdentifier) {
    return {
      [field]: `This identifier does not meet the requirements\n • Must be unique\n • Must contain at least 3 characters\n • Only letters, numbers, -, and _ are allowed\n • Cannot contain spaces`,
    };
  }
  return {};
};

const validateCompanyDetails = ({form}: {form: any}) => {
  const nameErrors = Validation.requiredStringField({
    form,
    field: 'companyForm.name',
    message: 'Please enter a company name.',
  });
  const identifierErrors = validateIdentifier({form, field: 'companyForm.identifier'});
  return {
    ...nameErrors,
    ...identifierErrors,
  };
};

const validateProductsOverview = ({form}: {form: any}) => {
  const supermoveProductForms = _.get(form.values, 'companyForm.supermoveProductForms');
  const incompleteActiveProductForms = supermoveProductForms.filter(
    (supermoveProductForm: SupermoveProductFormProps) =>
      supermoveProductForm.status === SupermoveProductStatus.ACTIVE &&
      !supermoveProductForm.isSetupComplete,
  );
  return {
    ...(incompleteActiveProductForms.length
      ? {
          'companyForm.supermoveProductForms':
            'Please set up the products before moving onto the next step.',
        }
      : {}),
  };
};

const validateProductsSales = ({form}: {form: any}) => {
  const supermoveProductForms = _.get(form.values, 'companyForm.supermoveProductForms');
  const index = _.findIndex(
    supermoveProductForms,
    (supermoveProductForm: SupermoveProductFormProps) =>
      supermoveProductForm.kind === SupermoveProductKind.SALES,
  );
  const numberOfLicensesErrors = Validation.requiredStringField({
    form,
    field: `companyForm.supermoveProductForms.${index}.numberOfLicenses`,
    message: 'Please enter a number.',
  });
  return {
    ...numberOfLicensesErrors,
  };
};

const validateFormForStep = ({stepKind, form}: {stepKind: string; form: any}): ValidationErrors => {
  switch (stepKind) {
    case CreateCompanyStepKinds.COMPANY_DETAILS:
      return validateCompanyDetails({form});
    case CreateCompanyStepKinds.PRODUCTS.OVERVIEW:
      return validateProductsOverview({form});
    case CreateCompanyStepKinds.PRODUCTS.SALES:
      return validateProductsSales({form});
    default:
      return {};
  }
};

const validateStep = async ({
  stepKind,
  form,
}: {
  stepKind: string;
  form: any;
}): Promise<ValidationErrors> => {
  const errors = validateFormForStep({stepKind, form});
  await form.setTouched(_.mapValues(errors, () => true));
  await form.setErrors(errors);
  return errors;
};

const CompanyForm = {
  new: _new,
  toForm,
  toMutation,

  // Helpers
  validateStep,
};

export default CompanyForm;
