// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useCallback, useEffect, useMountEffect, useQuery, useState} from '@supermove/hooks';
import {Storage} from '@supermove/sdk';

export interface AppContextProps {
  viewer?: {
    id: string;
    fullName: string;
    isEpd: boolean;
    viewingOrganization: {
      id: string;
      features: {
        isEnabledAdminViewAndEditSuperUsers: boolean;
      };
    };
  };
  isGlobalNavSidebarExpanded: boolean;
  toggleIsGlobalNavSidebarExpanded: () => void;
  globalNavigationTab: string;
  setGlobalNavigationTab: (tab: string) => void;
}

export const AppContext = React.createContext<AppContextProps>({
  viewer: undefined,
  isGlobalNavSidebarExpanded: false,
  toggleIsGlobalNavSidebarExpanded: () => {},
  globalNavigationTab: '',
  setGlobalNavigationTab: () => {},
});

const AppContextProvider = ({children}: React.PropsWithChildren<{}>) => {
  const [viewer, setViewer] = useState();
  const [globalNavigationTab, setGlobalNavigationTab] = useState('');
  const [isGlobalNavSidebarExpanded, setIsGlobalNavSidebarExpanded] = useState(false);
  const {data} = useQuery(AppContextProvider.query, {fetchPolicy: 'cache-and-network'});

  useEffect(() => {
    if (data) {
      setViewer(data.viewer);
    }
  }, [data]);

  const handleCheckSidebarIsExpanded = async () => {
    const value = await Storage.getItem('IS_GLOBAL_NAV_SIDEBAR_EXPANDED');
    return value === 'true';
  };
  useMountEffect(() => {
    handleCheckSidebarIsExpanded().then((value) => {
      setIsGlobalNavSidebarExpanded(value);
    });
  });

  const toggleIsGlobalNavSidebarExpanded = useCallback(async () => {
    const nextIsGlobalNavSidebarExpanded = !isGlobalNavSidebarExpanded;
    setIsGlobalNavSidebarExpanded(nextIsGlobalNavSidebarExpanded);
    await Storage.setItem('IS_GLOBAL_NAV_SIDEBAR_EXPANDED', nextIsGlobalNavSidebarExpanded);
  }, [isGlobalNavSidebarExpanded]);

  const store = {
    viewer,
    isGlobalNavSidebarExpanded,
    toggleIsGlobalNavSidebarExpanded,
    globalNavigationTab,
    setGlobalNavigationTab,
  };

  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AppContextProvider.query = gql`
  query AppContextProvider {
    ${gql.query}
    viewer {
      id
      fullName
      isEpd
      viewingOrganization {
        id
        features {
          isEnabledAdminViewAndEditSuperUsers: isEnabled(feature: "ADMIN_VIEW_AND_EDIT_SUPER_USERS")
        }
      }
    }
  }
`;

export default AppContextProvider;
