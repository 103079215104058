// Supermove imports
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App imports
import ChatCompletionForm from '@shared/modules/ChatCompletion/forms/ChatCompletionForm';

const useGetChatCompletionMutation = ({chatCompletionForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      chatCompletionForm: ChatCompletionForm.toForm(chatCompletionForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useGetChatCompletionMutation.mutation,
    variables: {
      chatCompletionForm: ChatCompletionForm.toMutation(form.values.chatCompletionForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useGetChatCompletionMutation.mutation = gql`
  mutation GetChatCompletionMutation($chatCompletionForm: ChatCompletionForm!) {
    response: getChatCompletion(chatCompletionForm: $chatCompletionForm) {
      ${gql.errors}
      errors {
        field
        message
      }
      completion {
        text
        index
        finishReason
      }
    }
  }
`;

export default useGetChatCompletionMutation;
