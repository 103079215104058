// App

export interface DuplicateGlobalDashboardFormProps {
  globalDashboardId: string;
}

const toForm = (globalDashboardForm: DuplicateGlobalDashboardFormProps) => ({
  globalDashboardId: globalDashboardForm.globalDashboardId,
});

const toMutation = (globalDashboardForm: DuplicateGlobalDashboardFormProps) => ({
  globalDashboardId: globalDashboardForm.globalDashboardId,
});

const DuplicateGlobalDashboardForm = {
  toForm,
  toMutation,
};

export default DuplicateGlobalDashboardForm;
