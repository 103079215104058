const USA = 'USA';
const CANADA = 'CANADA';

const LOCATION_COUNTRY_OPTIONS = [USA, CANADA] as const;

export type LocationCountryType = (typeof LOCATION_COUNTRY_OPTIONS)[number];

const getDisplay = (country: LocationCountryType) => {
  switch (country) {
    case USA:
      return 'United States';
    case CANADA:
      return 'Canada';
    default:
      return country;
  }
};

const getDropdownOptions = () =>
  LOCATION_COUNTRY_OPTIONS.map((country) => ({
    label: getDisplay(country),
    value: country,
  }));

const LocationCountry = {
  USA,
  CANADA,

  getDisplay,
  getDropdownOptions,
};

export default LocationCountry;
