export interface LogInAdminFormProps {
  email: string;
  password: string;
}

const _new = () => ({
  email: '',
  password: '',
});

const toForm = ({email, password}: LogInAdminFormProps) => ({
  email,
  password,
});

const toMutation = ({email, password}: LogInAdminFormProps) => ({
  email,
  password,
});

const LogInAdminForm = {
  new: _new,
  toForm,
  toMutation,
};

export default LogInAdminForm;
