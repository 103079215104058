// Supermove
import {colors} from '@supermove/styles';

// App
import {DropdownActionType} from '@shared/design/components/DropdownInput/components/DropdownSheet';
import DashboardStatus from '@shared/modules/GlobalDashboard/enums/DashboardStatus';

interface Props {
  handleDuplicate: () => void;
  handlePublish: () => void;
  handleRestoreAsDraft: () => void;
  handleRevertToDraft: () => void;
  handleArchive: () => void;
}

const getGlobalDashboardStatusChangeActionsMap = ({
  handleDuplicate,
  handlePublish,
  handleRestoreAsDraft,
  handleRevertToDraft,
  handleArchive,
}: Props) => {
  const restoreToDraft = {
    text: 'Restore report as draft',
    onPress: handleRestoreAsDraft,
  };
  const revertToDraft = {
    text: 'Revert to draft',
    onPress: handleRevertToDraft,
  };
  const publish = {
    text: 'Publish report to library',
    onPress: handlePublish,
  };
  const archive = {
    text: 'Archive report',
    color: colors.red.warning,
    onPress: handleArchive,
  };

  return {
    [DashboardStatus.ARCHIVED]: [restoreToDraft] as DropdownActionType[],
    [DashboardStatus.DRAFT]: [publish, archive] as DropdownActionType[],
    [DashboardStatus.PUBLISHED]: [revertToDraft, archive] as DropdownActionType[],
  };
};

export default getGlobalDashboardStatusChangeActionsMap;
