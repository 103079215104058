// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {Device} from '@supermove/sdk';
import {Typography, colors} from '@supermove/styles';

// App
import Banner from './Banner';

const Container = Styled.View`
  z-index: 1000;
`;

const Touchable = Styled.ButtonV2`
  margin-left: 6px;
`;

const Text = Styled.Text`
  ${Typography.Subheading}
  color: ${colors.white};
  text-decoration-line: underline;
`;

const UpdateAppBanner = () => {
  const responsive = useResponsive();

  return (
    <Container data-no-print>
      <Banner
        color={colors.blue.interactive}
        text={'A new update for Supermove is available.'}
        responsive={responsive}
      >
        <Touchable onPress={() => Device.restartApp()}>
          <Text>Press to Reload</Text>
        </Touchable>
      </Banner>
    </Container>
  );
};

export default UpdateAppBanner;
