// Supermove
import {gql} from '@supermove/graphql';
import {CompanyModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

export interface CompanyDetailsLocationFormType {
  companyId: string;
  locationCity: string;
  locationState: string;
  locationCountry: string;
}

const edit = withFragment(
  (company: CompanyModel) => ({
    companyId: company.id,
    locationCity: company.locationCity,
    locationState: company.locationState,
    locationCountry: company.locationCountry,
  }),
  gql`
    fragment CompanyDetailsLocationForm_edit on Company {
      id
      locationCity
      locationState
      locationCountry
    }
  `,
);

const toForm = ({
  companyId,
  locationCity,
  locationState,
  locationCountry,
}: CompanyDetailsLocationFormType) => ({
  companyId,
  locationCity,
  locationState,
  locationCountry,
});

const toMutation = ({
  companyId,
  locationCity,
  locationState,
  locationCountry,
}: CompanyDetailsLocationFormType) => ({
  companyId,
  locationCity,
  locationState,
  locationCountry,
});

const CompanyDetailsLocationForm = {
  edit,
  toForm,
  toMutation,
};

export default CompanyDetailsLocationForm;
