// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CompanyDetailsProfileForm, {
  CompanyDetailsProfileFormType,
} from '@shared/modules/Company/forms/CompanyDetailsProfileForm';

const useUpdateCompanyDetailsProfileMutation = ({
  companyDetailsProfileForm,
  onSuccess,
  onError,
}: {
  companyDetailsProfileForm: CompanyDetailsProfileFormType;
  onSuccess: (response: unknown) => void;
  onError: (error: unknown) => void;
}) => {
  const form = useForm({
    initialValues: {
      companyDetailsProfileForm: CompanyDetailsProfileForm.toForm(companyDetailsProfileForm),
    },
    enableReinitialize: true,
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateCompanyDetailsProfileMutation.mutation,
    variables: {
      companyDetailsProfileForm: CompanyDetailsProfileForm.toMutation(
        form.values.companyDetailsProfileForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateCompanyDetailsProfileMutation.mutation = gql`
  mutation useUpdateCompanyDetailsProfileMutation($companyDetailsProfileForm: CompanyDetailsProfileForm!) {
    response: updateCompanyDetailsProfile(companyDetailsProfileForm: $companyDetailsProfileForm) {
      ${gql.errors}
      company {
        id
      }
    }
  }
`;

export default useUpdateCompanyDetailsProfileMutation;
