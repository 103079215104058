// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';
import {Storage} from '@supermove/sdk';

import LogInAdminForm, {
  LogInAdminFormProps,
} from '@shared/modules/Authentication/forms/LogInAdminForm';

const useLogInAdminMutation = ({
  logInAdminForm,
  onSuccess,
  onError,
}: {
  logInAdminForm: LogInAdminFormProps;
  onSuccess: (response: any) => void;
  onError: (error: any) => void;
}) => {
  const form = useForm({
    initialValues: {
      logInAdminForm: LogInAdminForm.toForm(logInAdminForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useLogInAdminMutation.mutation,
    variables: {
      logInAdminForm: LogInAdminForm.toMutation(form.values.logInAdminForm),
    },
    onSuccess: async ({token, user}: {token: string; user: any}) => {
      if (token) {
        await Storage.setItem('token', token);
        await Storage.setItem('userId', user.id);
        onSuccess({token, user});
      }
    },
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useLogInAdminMutation.mutation = gql`
  mutation LogInAdminMutation($logInAdminForm: LogInAdminForm!) {
    response: logInAdmin(logInAdminForm: $logInAdminForm) {
      ${gql.errors}
      token
      user {
        id
      }
    }
  }
`;

export default useLogInAdminMutation;
