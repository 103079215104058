// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import UpdateGlobalDashboardStatusForm, {
  UpdateGlobalDashboardStatusFormProps,
} from '@shared/modules/GlobalDashboard/forms/UpdateGlobalDashboardStatusForm';

const useUpdateGlobalDashboardStatusMutation = ({
  globalDashboardForm,
  onSuccess,
  onError,
}: {
  globalDashboardForm: UpdateGlobalDashboardStatusFormProps;
  onSuccess: (response: any) => void;
  onError: (error: any) => void;
}) => {
  const form = useForm({
    initialValues: {
      updateGlobalDashboardStatusForm: UpdateGlobalDashboardStatusForm.toForm(globalDashboardForm),
    },
    enableReinitialize: true,
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateGlobalDashboardStatusMutation.mutation,
    variables: {
      updateGlobalDashboardStatusForm: UpdateGlobalDashboardStatusForm.toMutation(
        form.values.updateGlobalDashboardStatusForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateGlobalDashboardStatusMutation.mutation = gql`
  mutation useUpdateGlobalDashboardStatusMutation($updateGlobalDashboardStatusForm: UpdateGlobalDashboardStatusForm!) {
    response: updateGlobalDashboardStatus(updateGlobalDashboardStatusForm: $updateGlobalDashboardStatusForm) {
      ${gql.errors}
      globalDashboard {
        id
        uuid
        name
      }
    }
  }
`;

export default useUpdateGlobalDashboardStatusMutation;
