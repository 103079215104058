// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useMountEffect, useNavigation, useResponsive} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import useAppContext from 'modules/App/context/useAppContext';

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const Header = Styled.View`
  width: 100%;
  background-color: ${colors.white};
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  padding-horizontal: ${({responsive}: {responsive: any}) => (responsive.desktop ? '24' : '16')}px;
  padding-vertical: ${({responsive}: {responsive: any}) => (responsive.desktop ? '14' : '12')}px;
`;

const PageHeading = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

interface AdminAppPageProps {
  title?: string;
  HeaderElement?: React.ReactNode;
  isScrollable?: boolean;
  children: React.ReactNode;
}

const AdminAppPage: React.FC<AdminAppPageProps> & {
  HeaderContainer: typeof Header;
  PageHeadingText: typeof PageHeading;
} = ({title, HeaderElement, isScrollable = true, children}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigation();
  const {setGlobalNavigationTab, globalNavigationTab} = useAppContext();
  useMountEffect(() => {
    // Set the global navigation tab based on the current route if not already set
    // This is to ensure that the correct tab is highlighted when the user navigates to a page directly
    const {routeName} = navigator.state;
    if (routeName !== '' && routeName !== globalNavigationTab) {
      setGlobalNavigationTab(routeName);
    }
  });

  return (
    <Container>
      {HeaderElement || (
        <Header responsive={responsive}>
          <PageHeading responsive={responsive}>{title || ''}</PageHeading>
        </Header>
      )}
      {children}
    </Container>
  );
};

AdminAppPage.HeaderContainer = Header;
AdminAppPage.PageHeadingText = PageHeading;

export default AdminAppPage;
