// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import DuplicateGlobalDashboardForm, {
  DuplicateGlobalDashboardFormProps,
} from '@shared/modules/GlobalDashboard/forms/DuplicateGlobalDashboardForm';

const useDuplicateGlobalDashboardMutation = ({
  duplicateGlobalDashboardForm,
  onSuccess,
  onError,
}: {
  duplicateGlobalDashboardForm: DuplicateGlobalDashboardFormProps;
  onSuccess: (response: any) => void;
  onError: (error: any) => void;
}) => {
  const form = useForm({
    initialValues: {
      duplicateGlobalDashboardForm: DuplicateGlobalDashboardForm.toForm(
        duplicateGlobalDashboardForm,
      ),
    },
    enableReinitialize: true,
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDuplicateGlobalDashboardMutation.mutation,
    variables: {
      duplicateGlobalDashboardForm: DuplicateGlobalDashboardForm.toMutation(
        form.values.duplicateGlobalDashboardForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDuplicateGlobalDashboardMutation.mutation = gql`
  mutation useDuplicateGlobalDashboardMutation($duplicateGlobalDashboardForm: DuplicateGlobalDashboardForm!) {
    response: duplicateGlobalDashboard(duplicateGlobalDashboardForm: $duplicateGlobalDashboardForm) {
      ${gql.errors}
      globalDashboard {
        id
        uuid
        name
      }
    }
  }
`;

export default useDuplicateGlobalDashboardMutation;
