// Libraries
import React from 'react';

// App
import SuccessModal from '@shared/design/components/Modal/SmallModal/SuccessModal';
import useUpdateGlobalDashboardStatusMutation from '@shared/modules/GlobalDashboard/hooks/useUpdateGlobalDashboardStatusMutation';

interface PublishDashboardModalProps {
  globalDashboard: {id: string; name: string};
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
}

const PublishDashboardModal = ({
  globalDashboard,
  isOpen,
  handleClose,
  refetch,
}: PublishDashboardModalProps) => {
  const {form, handleSubmit, submitting} = useUpdateGlobalDashboardStatusMutation({
    globalDashboardForm: {globalDashboardId: globalDashboard.id, status: 'PUBLISHED'},
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <SuccessModal
      isSubmitting={submitting}
      isOpen={isOpen}
      title={`Publish to library?`}
      subtitle={`'${globalDashboard.name}' will be available to all customers in the Reports Library in the Office App.`}
      primaryActionText={'Confirm'}
      secondaryActionText={'Cancel'}
      handlePrimaryAction={handleSubmit}
      handleSecondaryAction={handleClose}
    />
  );
};

export default PublishDashboardModal;
