// Libraries
import _ from 'lodash';

// Supremove
import {generated, gqlTyped as gql} from '@supermove/graphql';
import {URL} from '@supermove/utils';

// App
import DashboardCategoryForm from '@shared/modules/DashboardCategory/forms/DashboardCategoryForm';
import DashboardTagForm from '@shared/modules/DashboardTag/forms/DashboardTagForm';

const _new = () => ({
  globalDashboardId: null,
  name: '',
  description: '',
  helpArticleUrl: '',
  isDefault: false,
  exploDashboardEmbedId: '',
  exploVariables: '',
  dashboardCategoryForm: null,
  dashboardTagForms: [],
});

export const GlobalDashboardFormEdit = gql(`
  fragment GlobalDashboardFormEdit on GlobalDashboard {
    id
    name
    description
    helpArticleUrl
    isDefault
    exploDashboardEmbedId
    exploVariables
    dashboardCategory {
      id
      ...DashboardCategoryFormEdit
    }
    dashboardTags {
      id
      tag {
        ...DashboardTagFormEdit
      }
    }
  }
`);

const edit = (globalDashboard: generated.GlobalDashboardFormEditFragment) => {
  return {
    globalDashboardId: globalDashboard.id,
    name: globalDashboard.name,
    description: globalDashboard.description,
    helpArticleUrl: globalDashboard.helpArticleUrl,
    isDefault: globalDashboard.isDefault,
    exploDashboardEmbedId: globalDashboard.exploDashboardEmbedId,
    exploVariables: globalDashboard.exploVariables,
    dashboardCategoryForm: globalDashboard.dashboardCategory
      ? DashboardCategoryForm.edit(globalDashboard.dashboardCategory)
      : null,
    dashboardTagForms: globalDashboard.dashboardTags?.reduce(
      (acc, dashboardTag) => {
        const tag = dashboardTag?.tag;
        if (tag) {
          acc.push(DashboardTagForm.edit(tag));
        }
        return acc;
      },
      [] as ReturnType<typeof DashboardTagForm.edit>[],
    ),
  };
};

const toForm = (
  globalDashboard: ReturnType<typeof edit> | ReturnType<typeof _new>,
): generated.GlobalDashboardForm => {
  return {
    globalDashboardId: globalDashboard.globalDashboardId
      ? _.toNumber(globalDashboard.globalDashboardId)
      : undefined,
    name: globalDashboard.name,
    description: globalDashboard.description,
    helpArticleUrl: globalDashboard.helpArticleUrl,
    isDefault: globalDashboard.isDefault,
    exploDashboardEmbedId: globalDashboard.exploDashboardEmbedId,
    exploVariables: globalDashboard.exploVariables,
    dashboardCategoryForm: globalDashboard.dashboardCategoryForm
      ? DashboardCategoryForm.toForm(globalDashboard.dashboardCategoryForm)
      : null,
    dashboardTagForms: globalDashboard.dashboardTagForms?.map((dashboardTagForm) =>
      DashboardTagForm.toForm(dashboardTagForm),
    ),
  };
};

const toMutation = (
  globalDashboardForm: generated.GlobalDashboardForm,
): generated.GlobalDashboardForm => {
  const helpArticleUrl = globalDashboardForm.helpArticleUrl?.trim();
  return {
    globalDashboardId: globalDashboardForm.globalDashboardId,
    name: globalDashboardForm.name,
    description: globalDashboardForm.description,
    helpArticleUrl: helpArticleUrl ? URL.prependHttp(helpArticleUrl) : null,
    isDefault: globalDashboardForm.isDefault,
    exploDashboardEmbedId: globalDashboardForm.exploDashboardEmbedId,
    exploVariables:
      globalDashboardForm.exploVariables && globalDashboardForm.exploVariables.trim()
        ? globalDashboardForm.exploVariables
        : null,
    dashboardCategoryForm: globalDashboardForm.dashboardCategoryForm
      ? DashboardCategoryForm.toMutation(globalDashboardForm.dashboardCategoryForm)
      : undefined,
    dashboardTagForms: globalDashboardForm.dashboardTagForms?.reduce((acc, dashboardTagForm) => {
      if (dashboardTagForm) {
        acc.push(DashboardTagForm.toMutation(dashboardTagForm));
      }
      return acc;
    }, [] as generated.DashboardTagForm[]),
  };
};

const GlobalDashboardForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default GlobalDashboardForm;
