// Supermove
import {gqlTyped as gql} from '@supermove/graphql';
import {useForm, useTypedFormMutation as useFormMutation} from '@supermove/hooks';

// App
import DashboardCategoryForm from '@shared/modules/DashboardCategory/forms/DashboardCategoryForm';

const useCreateDashboardCategoryMutation = ({
  dashboardCategoryForm,
  onSuccess,
  onError,
}: {
  dashboardCategoryForm: ReturnType<typeof DashboardCategoryForm.new>;
  onSuccess: (response: any) => void;
  onError: (error: any) => void;
}) => {
  const form = useForm({
    initialValues: {
      dashboardCategoryForm: DashboardCategoryForm.toForm(dashboardCategoryForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateDashboardCategoryMutation.mutation,
    variables: {
      dashboardCategoryForm: DashboardCategoryForm.toMutation(form.values.dashboardCategoryForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateDashboardCategoryMutation.mutation = gql(`
  mutation useCreateDashboardCategoryMutation($dashboardCategoryForm: DashboardCategoryForm!) {
    response: createDashboardCategory(dashboardCategoryForm: $dashboardCategoryForm) {
      errors {
        field
        message
      }
      dashboardCategory {
        id
      }
    }
  }
`);

export default useCreateDashboardCategoryMutation;
