// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useToast} from '@supermove/hooks';
import {CompanyModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import FieldValue from '@shared/design/components/Field/FieldValue';
import IconButton from '@shared/design/components/IconButton';
import TextTooltip from '@shared/design/components/TextTooltip';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import CompanyKind from '@shared/modules/Company/enums/CompanyKind';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const IconContainer = Styled.View`
`;

const NONE = 'None';

const CompanyDetailsProfilePanel = ({company}: {company: CompanyModel}) => {
  const responsive = useResponsive();
  const copyIdentifierSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Copied to clipboard.',
  });

  const handleCopyIdentifier = (value: string) => {
    /* global navigator */
    navigator.clipboard.writeText(value);
    copyIdentifierSuccessToast.handleToast();
  };

  return (
    <React.Fragment>
      <Row>
        <Column>
          <FieldValue label={'Name'} value={company.name} empty={NONE} isResponsive />
          <Space height={20} />
          <FieldValue
            label={'Company Type'}
            value={CompanyKind.getLabel(company.kind)}
            empty={NONE}
            isResponsive
          />
        </Column>
        <Space width={20} />
        <Column>
          <FieldValue.LabelText
            labelColor={colors.gray.secondary}
            isResponsive
            responsive={responsive}
          >
            Identifier
          </FieldValue.LabelText>
          <FieldValue.LabelSpace isResponsive responsive={responsive} />
          <Row style={{alignItems: 'center'}}>
            {company.slug ? (
              <React.Fragment>
                <TextTooltip text={'Copy to clipboard'} placement='top'>
                  <IconContainer>
                    <IconButton
                      source={Icon.Copy}
                      onPress={() => handleCopyIdentifier(company.slug)}
                    />
                  </IconContainer>
                </TextTooltip>
                <Space width={4} />
                <FieldValue.ValueText isResponsive responsive={responsive}>
                  {company.slug}
                </FieldValue.ValueText>
              </React.Fragment>
            ) : (
              <FieldValue.EmptyText isResponsive responsive={responsive}>
                {NONE}
              </FieldValue.EmptyText>
            )}
          </Row>
        </Column>
      </Row>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompanyDetailsProfilePanel.fragment = gql`
  fragment CompanyDetailsProfilePanel on Company {
    id
    name
    slug
    kind
  }
`;

export default CompanyDetailsProfilePanel;
