// Libraries
import React from 'react';

// Supermove
import {Space, Styled, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {CompanyModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import FieldValue from '@shared/design/components/Field/FieldValue';
import IconButton from '@shared/design/components/IconButton';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const NONE = 'None';

const ExternalLinkField = ({
  label,
  value,
  linkUrl,
}: {
  label: string;
  value: string;
  linkUrl: string;
}) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <FieldValue.LabelText labelColor={colors.gray.secondary} isResponsive responsive={responsive}>
        {label}
      </FieldValue.LabelText>
      <FieldValue.LabelSpace isResponsive responsive={responsive} />
      <Row>
        {value ? (
          <a target={'_blank'} href={linkUrl} style={{alignItems: 'center'}}>
            <IconButton source={Icon.ArrowUpRightFromSquare} isDisplayOnly />
            <Space width={4} />
            <FieldValue.ValueText isResponsive responsive={responsive} isLink>
              {value}
            </FieldValue.ValueText>
          </a>
        ) : (
          <FieldValue.EmptyText isResponsive responsive={responsive}>
            {NONE}
          </FieldValue.EmptyText>
        )}
      </Row>
    </React.Fragment>
  );
};

const CompanyDetailsExternalLinksPanel = ({company}: {company: CompanyModel}) => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <ExternalLinkField
            label={'Salesforce ID'}
            linkUrl={`https://supermove.my.salesforce.com/${company.salesforceId}`}
            value={company.salesforceId}
          />
        </Column>
        <Space width={20} />
        <Column>
          <ExternalLinkField
            label={'Slack Channel ID'}
            linkUrl={`https://supermove.slack.com/archives/${company.slackChannelId}`}
            value={company.slackChannelId}
          />
        </Column>
      </Row>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompanyDetailsExternalLinksPanel.fragment = gql`
  fragment CompanyDetailsExternalLinksPanel on Company {
    id
    salesforceId
    slackChannelId
  }
`;

export default CompanyDetailsExternalLinksPanel;
