// Libraries
import pluralize from 'pluralize';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {generated, gqlTyped as gql} from '@supermove/graphql';
import {useDrawer} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import FieldValue from '@shared/design/components/Field/FieldValue';
import PanelComponent from '@shared/design/components/Panel';

const LabelText = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.gray.secondary}
`;

const PageContainer = Styled.View`
  background-color: ${colors.gray.background};
`;

interface GlobalDashboardDetailsPageAboutPanelProps {
  globalDashboard: generated.GlobalDashboardDetailsPageAboutPanelFragment;
  manageCompaniesDrawer: ReturnType<typeof useDrawer>;
}

const GlobalDashboardDetailsPageAboutPanel = ({
  globalDashboard,
  manageCompaniesDrawer,
}: GlobalDashboardDetailsPageAboutPanelProps) => {
  return (
    <PageContainer>
      <PanelComponent>
        <PanelComponent.Header>
          <PanelComponent.HeaderText>About (Internal)</PanelComponent.HeaderText>
        </PanelComponent.Header>
        <PanelComponent.Body>
          <FieldValue
            label='Added To'
            isLink
            onPressValue={manageCompaniesDrawer.handleOpen}
            value={`${pluralize('Company', globalDashboard.companiesCount || 0, true)}`}
          />
          <Space height={16} />
          {globalDashboard.publishedAt && (
            <React.Fragment>
              <FieldValue
                label='Last Published'
                value={`${Datetime.convertToDisplayDatetime(globalDashboard.publishedAt)} by ${globalDashboard.publishedBy?.fullName}`}
              />
              <Space height={16} />
            </React.Fragment>
          )}
          <FieldValue
            label='Last Edited'
            value={`${Datetime.convertToDisplayDatetime(globalDashboard.updatedAt)} by ${globalDashboard.updatedBy?.fullName}`}
          />
          <LabelText />
        </PanelComponent.Body>
      </PanelComponent>
    </PageContainer>
  );
};

const GlobalDashboardDetailsPageAboutPanelFragment = gql(`
  fragment GlobalDashboardDetailsPageAboutPanel on GlobalDashboard {
    id
    uuid
    companiesCount
    publishedAt
    publishedBy {
      id
      fullName
    }
    updatedAt
    updatedBy {
      id
      fullName
    }
  }
`);

export default GlobalDashboardDetailsPageAboutPanel;
