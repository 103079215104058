// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import SearchBar from 'modules/App/components/SearchBar';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const MobileSearch = ({
  searchQuery,
  setSearchQuery,
  isVisible,
  handleClose,
  placeholder,
}: {
  searchQuery: string;
  setSearchQuery: (text: string) => void;
  isVisible: boolean;
  handleClose: () => void;
  placeholder?: string;
}) => {
  return isVisible ? (
    <Row
      style={{
        paddingVertical: 12,
        paddingHorizontal: 16,
        backgroundColor: colors.white,
        borderBottomWidth: 1,
        borderColor: colors.gray.border,
      }}
    >
      <SearchBar
        placeholder={placeholder || 'Search by company name or identifier'}
        iconColor={colors.blue.interactive}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      <Space width={16} />
      <TertiaryButton
        onPress={() => {
          handleClose();
          setSearchQuery('');
        }}
      >
        <Icon source={Icon.CircleXmark} size={16} color={colors.gray.secondary} />
      </TertiaryButton>
    </Row>
  ) : null;
};

export default MobileSearch;
