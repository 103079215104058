// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {Form} from '@supermove/hooks';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import {CompanyDetailsExternalLinksFormType} from '@shared/modules/Company/forms/CompanyDetailsExternalLinksForm';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

type FormType = Form<{
  companyDetailsExternalLinksForm: CompanyDetailsExternalLinksFormType;
}>;

const CompanyDetailsExternalLinksPanelEdit = ({form}: {form: FormType}) => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <FieldInput
            {...form}
            label={'Salesforce ID'}
            name={'companyDetailsExternalLinksForm.salesforceId'}
            input={{
              placeholder: 'Enter Salesforce ID',
            }}
            isResponsive
          />
        </Column>
        <Space width={20} />
        <Column>
          <FieldInput
            {...form}
            label={'Slack Channel ID'}
            name={'companyDetailsExternalLinksForm.slackChannelId'}
            input={{
              placeholder: 'Enter Slack Channel ID',
            }}
            isResponsive
          />
        </Column>
      </Row>
    </React.Fragment>
  );
};

export default CompanyDetailsExternalLinksPanelEdit;
