// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled} from '@supermove/components';
import {gqlTyped as gql, useTypedQuery as useQuery} from '@supermove/graphql';
import {useEffect, useModal, useResponsive, useUrlFilters} from '@supermove/hooks';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import ExploDashboard from '@shared/modules/Reports/components/ExploDashboard';
import SelectOrganizationModal from 'modules/Dashboards/DashboardDetails/components/SelectOrganizationModal';

import {GlobalDashboardDetailsPageFiltersType} from './types';

const Container = Styled.View`
  display: flex;
  flex: 1;
`;

const OrganizationSelectButtonContainer = Styled.View`
`;

interface ExploDashboardPanelProps {
  globalDashboard: {
    id: string;
    name: string;
    exploDashboardEmbedId: string;
    exploVariables?: string;
  };
  viewerId: number;
}

const ExploDashboardPanel = ({globalDashboard, viewerId}: ExploDashboardPanelProps) => {
  const responsive = useResponsive();
  const urlFilters = useUrlFilters<GlobalDashboardDetailsPageFiltersType>({
    getRoute: () => `/reports/${globalDashboard.id}`,
    filterKeys: ['organizationSlug'],
    navigationLib: 'react-navigation',
  });
  const selectOrganizationModal = useModal({
    name: 'Global Dashboard Details Explo Panel Select Organization Modal',
  });

  const {loading, data} = useQuery(ExploDashboardPanelQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      slug: urlFilters.get('organizationSlug'),
    },
  });

  const organization = data?.organization;

  useEffect(() => {
    if (data && !urlFilters.get('organizationSlug')) {
      if (data.viewer?.viewingOrganization?.slug) {
        urlFilters.handleUpdate({
          organizationSlug: data.viewer.viewingOrganization.slug,
        });
      }
    }
  }, [data, urlFilters.get('organizationSlug')]);

  return (
    <React.Fragment>
      <Container>
        <OrganizationSelectButtonContainer>
          <SecondaryButton
            isSmall
            iconSize={12}
            iconLeft={Icon.Eye}
            iconRight={Icon.AngleDown}
            text={organization?.name ? `View As: ${organization.name}` : `Select an organization`}
            onPress={selectOrganizationModal.handleOpen}
          />
        </OrganizationSelectButtonContainer>
        <Loading loading={loading}>
          {() => (
            <React.Fragment>
              <Space height={16} />
              <ExploDashboard
                name={globalDashboard.name}
                viewerId={viewerId}
                exploDashboardEmbedId={globalDashboard.exploDashboardEmbedId}
                exploUserGroupToken={organization?.exploUserGroupToken || ''}
                exploVariables={globalDashboard.exploVariables}
                style={{
                  height: responsive.desktop ? undefined : 564,
                }}
              />
            </React.Fragment>
          )}
        </Loading>
      </Container>
      <SelectOrganizationModal
        selectedOrganizationSlug={urlFilters.get('organizationSlug')}
        isOpen={selectOrganizationModal.isOpen}
        handleClose={selectOrganizationModal.handleClose}
        handleOrganizationSelect={(organizationSlug: string) => {
          urlFilters.handleUpdate({
            organizationSlug,
          });
          selectOrganizationModal.handleClose();
        }}
      />
    </React.Fragment>
  );
};

const ExploDashboardPanelQuery = gql(`
  query ExploDashboardPanel($slug: String) {
    viewer {
      id
      viewingOrganization {
        id
        slug
      }
    }
    organization(slug: $slug) {
      id
      ...OrganizationItem
    }
  }
`);

export default ExploDashboardPanel;
