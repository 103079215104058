// Supermove
import {gql} from '@supermove/graphql';
import {SupermoveProductModel} from '@supermove/models';
import {withFragment, Datetime} from '@supermove/utils';

export interface SupermoveProductSettingsFormType {
  supermoveProductId: string;
  deployedDate: Date | string | null;
}

const edit = withFragment(
  (supermoveProduct: SupermoveProductModel) => ({
    supermoveProductId: supermoveProduct.id,
    deployedDate: supermoveProduct.deployedDate,
  }),
  gql`
    fragment SupermoveProductSettingsForm_edit on SupermoveProduct {
      id
      deployedDate
    }
  `,
);

const toForm = ({supermoveProductId, deployedDate}: SupermoveProductSettingsFormType) => ({
  supermoveProductId,
  deployedDate: Datetime.toFormDate(deployedDate),
});

const toMutation = ({supermoveProductId, deployedDate}: SupermoveProductSettingsFormType) => ({
  supermoveProductId,
  deployedDate: deployedDate ? Datetime.toMutationDate(deployedDate) : null,
});

const SupermoveProductSettingsForm = {
  edit,
  toForm,
  toMutation,
};

export default SupermoveProductSettingsForm;
