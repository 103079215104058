const UNKNOWN = 'UNKNOWN';
const STARTER_SMB = 'STARTER_SMB';
const SMB = 'SMB';
const MID_MARKET = 'MID_MARKET';
const ENTERPRISE = 'ENTERPRISE';

const COMPANY_SIZE_OPTIONS = [UNKNOWN, STARTER_SMB, SMB, MID_MARKET, ENTERPRISE] as const;

export type CompanySizeType = (typeof COMPANY_SIZE_OPTIONS)[number];

const getLabel = (kind: CompanySizeType) => {
  switch (kind) {
    case UNKNOWN:
      return 'Unknown';
    case STARTER_SMB:
      return 'Starter SMB';
    case SMB:
      return 'SMB';
    case MID_MARKET:
      return 'Mid-Market';
    case ENTERPRISE:
      return 'Enterprise';
    default:
      return kind;
  }
};

const CompanySize = {
  UNKNOWN,
  STARTER_SMB,
  SMB,
  MID_MARKET,
  ENTERPRISE,

  getLabel,
};

export default CompanySize;
