// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {Form} from '@supermove/hooks';

// App
import DateFieldComponent from '@shared/design/components/Field/DateField';
import {CompanyDetailsHistoryFormType} from '@shared/modules/Company/forms/CompanyDetailsHistoryForm';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

type FormType = Form<{
  companyDetailsHistoryForm: CompanyDetailsHistoryFormType;
}>;

const CompanyDetailsHistoryPanelEdit = ({form}: {form: FormType}) => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <DateFieldComponent
            form={form}
            label={'Created Date'}
            field={'companyDetailsHistoryForm.createdAt'}
            isFullWidth
            disabled
          />
        </Column>
        <Space width={20} />
        <Column>
          <DateFieldComponent
            form={form}
            label={'Contract Signed Date'}
            field={'companyDetailsHistoryForm.contractSignedDate'}
            isFullWidth
            isClearable
          />
        </Column>
      </Row>
    </React.Fragment>
  );
};

export default CompanyDetailsHistoryPanelEdit;
