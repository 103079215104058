// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CompanyDetailsExternalLinksForm, {
  CompanyDetailsExternalLinksFormType,
} from '@shared/modules/Company/forms/CompanyDetailsExternalLinksForm';

const useUpdateCompanyDetailsExternalLinksMutation = ({
  companyDetailsExternalLinksForm,
  onSuccess,
  onError,
}: {
  companyDetailsExternalLinksForm: CompanyDetailsExternalLinksFormType;
  onSuccess: (response: unknown) => void;
  onError: (error: unknown) => void;
}) => {
  const form = useForm({
    initialValues: {
      companyDetailsExternalLinksForm: CompanyDetailsExternalLinksForm.toForm(
        companyDetailsExternalLinksForm,
      ),
    },
    enableReinitialize: true,
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateCompanyDetailsExternalLinksMutation.mutation,
    variables: {
      companyDetailsExternalLinksForm: CompanyDetailsExternalLinksForm.toMutation(
        form.values.companyDetailsExternalLinksForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateCompanyDetailsExternalLinksMutation.mutation = gql`
  mutation useUpdateCompanyDetailsExternalLinksMutation($companyDetailsExternalLinksForm: CompanyDetailsExternalLinksForm!) {
    response: updateCompanyDetailsExternalLinks(companyDetailsExternalLinksForm: $companyDetailsExternalLinksForm) {
      ${gql.errors}
      company {
        id
      }
    }
  }
`;

export default useUpdateCompanyDetailsExternalLinksMutation;
