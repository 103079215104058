// Libraries
import _ from 'lodash';

export interface Step {
  kind: string;
  title: string;
  description: string;
}

const EMPTY_STEP: Step = {
  kind: '',
  title: '',
  description: '',
};

const LICENSES = 'LICENSES';

const STEPS: Step[] = [
  {
    kind: LICENSES,
    title: 'Licenses',
    description: 'Enter the number of people that will be using AI Sales Copilot.',
  },
];

const getStep = (kind: string): Step => {
  return _.find(STEPS, (step) => step.kind === kind) || EMPTY_STEP;
};

const getPreviousStep = (kind: string): Step => {
  const index = _.findIndex(STEPS, (step) => step.kind === kind);
  const step = index <= 0 ? undefined : STEPS[index - 1];
  return step || EMPTY_STEP;
};

const getNextStep = (kind: string): Step => {
  const index = _.findIndex(STEPS, (step) => step.kind === kind);
  const step = index < 0 || index >= STEPS.length - 1 ? undefined : STEPS[index + 1];
  return step || EMPTY_STEP;
};

const AddSalesAppToCompanyStep = {
  LICENSES,

  STEPS,
  getStep,
  getPreviousStep,
  getNextStep,

  getPreviousStepKind: (kind: string) => AddSalesAppToCompanyStep.getPreviousStep(kind).kind,
  getNextStepKind: (kind: string) => AddSalesAppToCompanyStep.getNextStep(kind).kind,
};

export default AddSalesAppToCompanyStep;
