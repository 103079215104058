// Libraries
import React from 'react';

// Supermove

// App
import {Form} from '@supermove/hooks';

import {DropdownActionType} from '@shared/design/components/DropdownInput/components/DropdownSheet';
import {useEditableSortableListContext} from '@shared/modules/App/components/EditableSortableList/EditableSortableListContext';
import EditableSortableListItemEdit from '@shared/modules/App/components/EditableSortableList/EditableSortableListItemEdit';
import EditableSortableListItemView from '@shared/modules/App/components/EditableSortableList/EditableSortableListItemView';

const EditableSortableListItem = <T extends Form<any>>({
  index,
  fieldName,
  form,
  isDisabled,
  isEditable,
  itemActions,
  inputPlaceholder,
  afterNameComponent,
  handleNameSet,
  handleCancelEdit,
}: {
  index: number;
  fieldName: string;
  form: T;
  inputPlaceholder: string;
  isDisabled?: boolean;
  isEditable: boolean;
  itemActions: (DropdownActionType & {onPress: (form: T) => void})[];
  afterNameComponent?: React.ReactNode;
  handleNameSet: () => void;
  handleCancelEdit: () => void;
}) => {
  const {indexOfEdit, setIndexOfEdit} = useEditableSortableListContext();
  const isEditing = indexOfEdit !== null;

  return index === indexOfEdit ? (
    <EditableSortableListItemEdit
      key={index}
      fieldName={fieldName}
      form={form}
      inputPlaceholder={inputPlaceholder}
      isDisabled={isDisabled}
      handleNameSet={() => {
        handleNameSet();
        setIndexOfEdit(null);
      }}
      handleCancelEdit={() => {
        handleCancelEdit();
        form.resetForm();
        setIndexOfEdit(null);
      }}
    />
  ) : (
    <EditableSortableListItemView
      key={index}
      fieldName={fieldName}
      form={form}
      isEditable={isEditable}
      isDisabled={isEditing}
      itemActions={itemActions}
      afterNameComponent={afterNameComponent}
      handleEdit={() => setIndexOfEdit(index)}
    />
  );
};

export default EditableSortableListItem;
