// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {CompanyModel} from '@supermove/models';

// App
import FieldValue from '@shared/design/components/Field/FieldValue';
import CompanySize from '@shared/modules/Company/enums/CompanySize';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const NONE = 'None';

const CompanyDetailsSizePanel = ({company}: {company: CompanyModel}) => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <FieldValue
            label={'Number of Trucks'}
            value={company.contractedTruckQuantity}
            empty={NONE}
            isResponsive
          />
        </Column>
        <Space width={20} />
        <Column>
          <FieldValue
            label={'Size'}
            value={CompanySize.getLabel(company.size)}
            empty={NONE}
            isResponsive
          />
        </Column>
      </Row>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompanyDetailsSizePanel.fragment = gql`
  fragment CompanyDetailsSizePanel on Company {
    id
    contractedTruckQuantity
    size
  }
`;

export default CompanyDetailsSizePanel;
