// Libraries
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {useNavigation, useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import SidebarItem from '@shared/modules/Navigation/components/SidebarItem';
import useAppContext from 'modules/App/context/useAppContext';

const Container = Styled.View`
  width: ${({desktop, isExpanded}: {desktop: boolean; isExpanded: boolean}) =>
    !desktop ? '100%' : isExpanded ? '164px' : '64px'};
  border-right-width: ${({desktop}: {desktop: boolean}) => (!desktop ? 0 : 1)}px;
  border-color: ${colors.gray.border};
  ${({desktop}: {desktop: boolean}) => (!desktop ? 'flex: 1;' : '')}
  background-color: ${colors.white};
`;

export interface NavigationItem {
  name: string;
  icon: string | string[];
  to: string;
  isHidden?: boolean;
}

const getNavigationItems = ({
  isEpd,
  isSuperAdminsEnabled,
}: {
  isEpd: boolean;
  isSuperAdminsEnabled: boolean;
}) =>
  [
    {
      name: 'Companies',
      icon: Icon.Building,
      subPages: [
        'CompanyDetailsPageMobileNavigation',
        'CompanyDetailsOverviewPage',
        'CompanyDetailsProductsPage',
        'CompanyModulesCalendarsPage',
        'CompanyModulesCommunicationPage',
        'CompanyModulesEquipmentAndMaterialsPage',
        'CompanyModulesInventoryPage',
        'CompanyModulesPaymentsPage',
        'CompanyModulesStoragePage',
        'CompanyModulesTimesheetsPage',
        'CompanyDetailsAiSalesCopilotGeneralPage',
      ],
      to: 'CompaniesListPage',
    },
    {
      name: 'Reports',
      icon: Icon.ChartLineUp,
      to: 'GlobalDashboardsPage',
      subPages: ['GlobalDashboardDetailsPage'],
    },
    {
      name: 'Super Users',
      icon: Icon.UserShield,
      to: 'SuperAdminsListPage',
      isHidden: !isSuperAdminsEnabled,
    },
    {
      name: 'AI Testing',
      icon: Icon.Robot,
      to: 'AITestingPage',
      isHidden: !isEpd,
    },
  ].filter(({isHidden}) => !isHidden);

const GlobalSidebar: React.FC<{}> = () => {
  const {navigator} = useNavigation();
  const responsive = useResponsive();
  const {viewer, isGlobalNavSidebarExpanded, setGlobalNavigationTab, globalNavigationTab} =
    useAppContext();
  const isExpanded = isGlobalNavSidebarExpanded || !responsive.desktop;

  return (
    <Container isExpanded={isExpanded} {...responsive}>
      <ScrollView contentContainerStyle={{padding: 8}}>
        {getNavigationItems({
          isEpd: !!viewer?.isEpd,
          isSuperAdminsEnabled:
            !!viewer?.viewingOrganization.features.isEnabledAdminViewAndEditSuperUsers,
        }).map(({name, icon, to, subPages}, index: number) => (
          <React.Fragment key={to}>
            {index > 0 && <Space height={16} />}
            <SidebarItem
              name={name}
              icon={icon}
              isSelected={globalNavigationTab === to || subPages?.includes(globalNavigationTab)}
              onPress={() => {
                setGlobalNavigationTab(to);
                navigator.navigate(to);
              }}
              isExpanded={isExpanded}
            />
          </React.Fragment>
        ))}
      </ScrollView>
    </Container>
  );
};

export default GlobalSidebar;
