// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useHover} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ItemText = Styled.Text`
  ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isSelected || vars.isHovered ? Typography.Label : Typography.Body)}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const SidebarItemContainer = Styled.View`
  width: 100%;
  padding: 12px;
  align-items: center;
  justify-content: flex-start;
  border-width: 1px;
  border-color: ${({
    // @ts-expect-error TS(2339): Property 'isSelected' does not exist on type 'Them... Remove this comment to see the full error message
    isSelected,
  }) => (isSelected ? colors.gray.border : colors.transparent)};
  border-radius: 4px;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'isHovered' does not exist on type 'Theme... Remove this comment to see the full error message
    isHovered,
    // @ts-expect-error TS(2339): Property 'isSelected' does not exist on type 'Theme... Remove this comment to see the full error message
    isSelected,
  }) => (isHovered || isSelected ? colors.gray.background : colors.transparent)};
  flex-direction: row;
`;

const SidebarButton = Styled.ButtonV2``;

const getColor = ({isHovered, isSelected}: any) =>
  isSelected ? colors.blue.interactive : isHovered ? colors.gray.primary : colors.gray.tertiary;

const TooltipWrapper = ({name, isExpanded, children}: any) => {
  if (isExpanded) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <TextTooltip text={name} placement={'right'}>
      {children}
    </TextTooltip>
  );
};

const SidebarItemButton = ({
  innerRef,
  isSelected,
  isHovered,
  isExpanded,
  name,
  onPress,
  children,
}: any) => {
  return (
    <TooltipWrapper name={name} isExpanded={isExpanded}>
      <SidebarButton onPress={onPress}>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <SidebarItemContainer ref={innerRef} isSelected={isSelected} isHovered={isHovered}>
          {children}
        </SidebarItemContainer>
      </SidebarButton>
    </TooltipWrapper>
  );
};

type OwnSidebarItemProps = {
  name: string;
  isSelected: boolean;
  icon: string | string[];
  onPress: (...args: any[]) => any;
  isExpanded?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'SidebarItemProps' circularly reference... Remove this comment to see the full error message
type SidebarItemProps = OwnSidebarItemProps & typeof SidebarItem.defaultProps;

// @ts-expect-error TS(7022): 'SidebarItem' implicitly has type 'any' because it... Remove this comment to see the full error message
const SidebarItem = ({name, isSelected, icon, onPress, isExpanded}: SidebarItemProps) => {
  const {isHovered, ref} = useHover();
  return (
    <SidebarItemButton
      innerRef={ref}
      onPress={onPress}
      isSelected={isSelected}
      isHovered={isHovered}
      isExpanded={isExpanded}
      name={name}
    >
      <Icon
        source={icon}
        color={getColor({isHovered, isSelected})}
        size={20}
        style={{height: 20, width: 20}}
      />
      {isExpanded && (
        <Row style={{flex: 1}}>
          <Space width={8} />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <ItemText vars={{isSelected, isHovered}} color={getColor({isHovered, isSelected})}>
            {name}
          </ItemText>
        </Row>
      )}
    </SidebarItemButton>
  );
};

SidebarItem.defaultProps = {
  isExpanded: true,
};

export default SidebarItem;
