// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {usePopover, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import {DropdownActionType} from '@shared/design/components/DropdownInput/components/DropdownSheet';
import IconButton from '@shared/design/components/IconButton';

const ItemContainer = Styled.View`
  background-color: ${colors.white};
  border-radius: 8px;
  padding: 12px 16px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const NameText = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.gray.primary};
`;

const TextContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ActionContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const EllipseButton = ({
  isDisabled,
  actions,
}: {
  isDisabled: boolean;
  actions: DropdownActionType[];
}) => {
  const actionsPopover = usePopover();
  return (
    <ActionMenuPopover popover={actionsPopover} width={200} actions={actions}>
      <IconButton
        source={IconButton.SOURCE.EllipsisV}
        onPress={actionsPopover.handleToggle}
        isSecondary
        isDisabled={isDisabled}
      />
    </ActionMenuPopover>
  );
};

const EditableSortableListItemView = <T extends {values: any}>({
  fieldName,
  form,
  isEditable,
  isDisabled,
  itemActions,
  afterNameComponent,
  handleEdit,
}: {
  fieldName: string;
  form: T;
  isEditable: boolean;
  isDisabled: boolean;
  itemActions: (DropdownActionType & {onPress: (form: T) => void})[];
  afterNameComponent?: React.ReactNode;
  handleEdit: () => void;
}) => {
  const responsive = useResponsive();

  const linkedActions = itemActions.filter((action) => ({
    ...action,
    onPress: () => {
      action.onPress(form);
    },
  }));

  return (
    <ItemContainer>
      <TextContainer>
        <NameText responsive={responsive}>{_.get(form.values, fieldName)}</NameText>
        <Space width={8} />
        {afterNameComponent}
      </TextContainer>
      <ActionContainer>
        {isEditable && (
          <IconButton onPress={handleEdit} isDisabled={isDisabled} source={IconButton.SOURCE.Pen} />
        )}
        <EllipseButton isDisabled={isDisabled} actions={linkedActions} />
      </ActionContainer>
    </ItemContainer>
  );
};

export default EditableSortableListItemView;
