// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {Form} from '@supermove/hooks';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import {CompanyDetailsLocationFormType} from '@shared/modules/Company/forms/CompanyDetailsLocationForm';
import LocationCountry from '@shared/modules/Location/enums/LocationCountry';
import LocationState from '@shared/modules/Location/enums/LocationState';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

type FormType = Form<{
  companyDetailsLocationForm: CompanyDetailsLocationFormType;
}>;

const StateInput = ({form, isDisabled}: {form: FormType; isDisabled: boolean}) => (
  <FieldInput
    {...form}
    label={'State'}
    name={'companyDetailsLocationForm.locationState'}
    input={{
      placeholder: 'Enter state',
      disabled: isDisabled,
    }}
    isResponsive
  />
);

const StateInputUSA = ({form}: {form: FormType}) => (
  <FieldInput
    {...form}
    label={'State'}
    name={'companyDetailsLocationForm.locationState'}
    component={DropdownInput}
    input={{
      placeholder: 'Enter state',
      options: LocationState.getDropdownOptionsUSA(),
      setFieldValue: form.setFieldValue,
      style: {width: '100%'},
    }}
    isClearable
    isResponsive
  />
);

const CompanyDetailsLocationPanelEdit = ({form}: {form: FormType}) => {
  const formCountryValue = _.get(form.values, 'companyDetailsLocationForm.locationCountry');
  return (
    <React.Fragment>
      <Row>
        <Column>
          <FieldInput
            {...form}
            label={'Country'}
            name={'companyDetailsLocationForm.locationCountry'}
            component={DropdownInput}
            style={{
              zIndex: 100,
            }}
            input={{
              placeholder: 'Enter country',
              options: LocationCountry.getDropdownOptions(),
              setFieldValue: form.setFieldValue,
              style: {width: '100%'},
            }}
            isClearable
            isResponsive
          />
          <Space height={20} />
          <FieldInput
            {...form}
            label={'City'}
            name={'companyDetailsLocationForm.locationCity'}
            style={{
              zIndex: 99,
            }}
            input={{
              placeholder: 'Enter city',
            }}
            isResponsive
          />
        </Column>
        <Space width={20} />
        <Column>
          {formCountryValue === LocationCountry.USA ? (
            <StateInputUSA form={form} />
          ) : (
            <StateInput form={form} isDisabled={!formCountryValue} />
          )}
        </Column>
      </Row>
    </React.Fragment>
  );
};

export default CompanyDetailsLocationPanelEdit;
