// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {Form} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import IconButton from '@shared/design/components/IconButton';

const ItemContainer = Styled.View`
  background-color: ${colors.white};
  border-radius: 8px;
  padding: 12px 16px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const ActionContainer = Styled.View`
  flex-direction: row;
  align-items: flex-end;
`;

const EditableSortableListItemEdit = <T extends Form<any>>({
  fieldName,
  form,
  inputPlaceholder,
  isDisabled,
  handleNameSet,
  handleCancelEdit,
}: {
  fieldName: string;
  form: T;
  inputPlaceholder: string;
  isDisabled?: boolean;
  handleNameSet: () => void;
  handleCancelEdit: (form: T) => void;
}) => {
  return (
    <React.Fragment>
      <ItemContainer>
        <FieldInput
          {...form}
          name={fieldName}
          input={{
            autoFocus: true,
            placeholder: inputPlaceholder,
            style: {
              width: '100%',
            },
          }}
          style={{flex: 1}}
          handleEnter={() => handleNameSet()}
        />
        <Space width={24} />
        <ActionContainer>
          <IconButton onPress={() => handleNameSet()} isDisabled={isDisabled} source={Icon.Check} />
          <Space width={12} />
          <IconButton
            onPress={() => handleCancelEdit(form)}
            source={Icon.XmarkLarge}
            isDisabled={isDisabled}
            isSecondary
          />
          <Space width={4} />
        </ActionContainer>
      </ItemContainer>
    </React.Fragment>
  );
};

export default EditableSortableListItemEdit;
