const ACTIVE = 'ACTIVE';
const INACTIVE = 'INACTIVE';
const INVITED = 'INVITED';

const ProductUserStatus = {
  ACTIVE,
  INACTIVE,
  INVITED,
};

export default ProductUserStatus;
