// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import SupermoveProductForm from '@shared/modules/SupermoveProduct/forms/SupermoveProductForm';

const useUpsertSupermoveProductMutation = ({supermoveProductForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      supermoveProductForm: SupermoveProductForm.toForm(supermoveProductForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpsertSupermoveProductMutation.mutation,
    variables: {
      supermoveProductForm: SupermoveProductForm.toMutation(form.values.supermoveProductForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpsertSupermoveProductMutation.mutation = gql`
  mutation useUpsertSupermoveProductMutation($supermoveProductForm: SupermoveProductForm!) {
    response: upsertSupermoveProduct(supermoveProductForm: $supermoveProductForm) {
      company {
        id
      }
      ${gql.errors}
    }
  }
`;

export default useUpsertSupermoveProductMutation;
