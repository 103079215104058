// Supermove
import {gqlTyped as gql} from '@supermove/graphql';
import {useForm, useTypedFormMutation as useFormMutation} from '@supermove/hooks';

// App
import DashboardCategoryForm from '@shared/modules/DashboardCategory/forms/DashboardCategoryForm';

const useDeleteDashboardCategoryMutation = ({
  dashboardCategoryForm,
  onSuccess,
  onError,
}: {
  dashboardCategoryForm: ReturnType<typeof DashboardCategoryForm.edit>;
  onSuccess: () => void;
  onError: (errors: any) => void;
}) => {
  const form = useForm({
    initialValues: {
      dashboardCategoryForm: DashboardCategoryForm.toForm(dashboardCategoryForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteDashboardCategoryMutation.mutation,
    variables: {
      dashboardCategoryForm: DashboardCategoryForm.toMutation(form.values.dashboardCategoryForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteDashboardCategoryMutation.mutation = gql(`
  mutation useDeleteDashboardCategoryMutation($dashboardCategoryForm: DashboardCategoryForm!) {
    response: deleteDashboardCategory(dashboardCategoryForm: $dashboardCategoryForm) {
      errors {
        field
        message
      }
      dashboardCategory {
        id
      }
    }
  }
`);

export default useDeleteDashboardCategoryMutation;
