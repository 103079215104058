// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {CompanyModel} from '@supermove/models';
import {Datetime} from '@supermove/utils';

// App
import FieldValue from '@shared/design/components/Field/FieldValue';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const NONE = 'None';

const CompanyDetailsHistoryPanel = ({company}: {company: CompanyModel}) => {
  const {createdAt, contractSignedDate} = company;
  return (
    <React.Fragment>
      <Row>
        <Column>
          <FieldValue
            label={'Created Date'}
            value={
              createdAt
                ? Datetime.convertToDisplayDate(createdAt, Datetime.DISPLAY_SHORT_DATE)
                : null
            }
            empty={NONE}
            isResponsive
          />
        </Column>
        <Space width={20} />
        <Column>
          <FieldValue
            label={'Contract Signed Date'}
            value={
              contractSignedDate
                ? Datetime.convertToDisplayDate(contractSignedDate, Datetime.DISPLAY_SHORT_DATE)
                : null
            }
            empty={NONE}
            isResponsive
          />
        </Column>
      </Row>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompanyDetailsHistoryPanel.fragment = gql`
  fragment CompanyDetailsHistoryPanel on Company {
    id
    createdAt
    contractSignedDate
  }
`;

export default CompanyDetailsHistoryPanel;
